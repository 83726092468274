import React from 'react';
import { ChakraProvider} from '@chakra-ui/react'

import MarketingHeader from './components/MarketingHeader';
import MarketingFooter from './components/MarketingFooter';
import ScrollToTopButton from './components/scrollTopButton';
import Banner from './components/Banner';
import WhatWedo from './sections/whatWedo';
import About from './sections/about';
import HowWeDo from './sections/howWeDo';
import Technology from './sections/technology';
import Contact from './sections/contact';

import './main.css'

export const MarketingMain = () => {
  return (
  <React.StrictMode>
    <ChakraProvider>
      <div className='marketing'>
        <MarketingHeader />
        <Banner />
        <WhatWedo />
        <About />
        <HowWeDo />
        <Technology />
        <Contact />
        {/*       
        <Testimonial />
        */}
        <MarketingFooter />
        <ScrollToTopButton />
      </div>
    </ChakraProvider>
  </React.StrictMode>
  );
};
