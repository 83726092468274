import React, { useContext, useEffect, useState } from 'react';

import { collection, doc, setDoc } from 'firebase/firestore';

import { RezylistTextField } from '../components/form/Inputs';
import {  AppBar, Button, Toolbar, Box, Checkbox, FormControlLabel, ListItemText, MenuItem, Select, Typography, TableContainer, Paper, FormControl, InputLabel } from '@mui/material';

import { getError, getVenueById, showErrorToast, showSuccessToast } from '../utils';
import { RoomTypeDnD } from '../components/RoomTypeDnD';
import { useNavigate, useParams } from 'react-router-dom';
import { FirebaseContext } from '../firebase/FirebaseContext';

export const VenueForm: React.FC<any> = () => {
  const { venueId } = useParams();
  const [idError, setIdError] = useState<boolean>(false);
  const [reservationPMS, setReservationPMS] = useState<boolean>(false);
  const [roomTypeList, setRoomTypeList] = useState<any[]>([]);
  // const [pmsSource, setPmsSource] = useState<string>('priceline');
  const [venue, setVenue] = useState<any>();
  const { db } = useContext(FirebaseContext);
  const navigate = useNavigate();

React.useEffect(() => {
  if (!venueId) { return }
  const getVenueAsync = async (id:string) => {
      const venue = await getVenueById(db, id);
      if (!venue || !venue.uid || !venue.venue_name) {
        showErrorToast('Invalid Venue');
        navigate('/dashboard/venues');
      }
      setVenue(venue);
  }
  getVenueAsync(venueId);

}, [db, navigate, venueId]);

  useEffect(() => {
    if (venue && venue.room_types) {
      setRoomTypeList(venue.room_types);
    }
    if (venue && venue.reservation_pms_id) {
      setReservationPMS(true);
    }
  }, [venue])

  const updateRoomTypeList = async (updatedList: any[]) => {
    const newList = updatedList.map((item, index) => {
      return {
        ...item,
        sort_order: index
      }
    })
    setRoomTypeList(newList);
  }
  const handleSubmitVenue = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIdError(false);

    let docId = String(venueId || venue?.uid || '').trim()
    const regex = new RegExp('^[a-z0-9_-]+$', 'g');
    if (!docId || !regex.test(docId)) {
      showErrorToast('Id must be all lower case, no spaces');
      setIdError(true);
      return;
    }

    if (reservationPMS) {
      if (!venue.reservation_pms_source || !venue.reservation_pms_id) {
        showErrorToast('Reservation PMS detail required');
        return;
      }
      if (venue.pms_source === venue.reservation_pms_source) {
        showErrorToast('PMS Source for reservations must be unique.  Otherwise uncheck the reservation PMS option');
        return;
      }
    } else {
      delete venue.reservation_pms_source;
      delete venue.reservation_pms_id;
    }
    const payload: any = {
      ...venue,
      room_types: roomTypeList
    }
    try {
      await setDoc(doc(collection(db, 'venues'), docId), payload);
      if (!String(docId).startsWith('test__')) {
        await setDoc(doc(collection(db, 'settings'), docId), 
        {
          pms_id: venue.pms_id || 0,
          pms_source: venue.pms_source || ''
        }, { merge: true });
        if (reservationPMS) {
          await setDoc(doc(collection(db, 'settings'), `${docId}_reservations`), 
          {
            pms_id: venue.reservation_pms_id,
            pms_source: venue.reservation_pms_source 
          }, { merge: true });
        }
      }
      showSuccessToast('Venue Data Saved');
      navigate('/dashboard/venues');
    } catch (e) {
      showErrorToast(getError(e).message)
    }
  }

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <div style={{marginRight: 16}}>
            <Typography
              component="h1"
              variant='h5'
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {venueId ? `Update ${venue?.uid}` : 'Add Venue'}
            </Typography>
            <Typography sx={{fontSize: 12}}>Venues starting with "test___" will not run availability checks
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          <div style={{}}>
            {venue?.venue_icon ? <img src={venue.venue_icon} style={{height: 40, width: 40}} alt='venue icon' /> : <></>}
          </div>

          {/* <SearchBox /> */}
        </Toolbar>
      </AppBar>
    </Box>
    <TableContainer component={Paper} sx={{display: 'table', width: '100%', padding: 2}}>
    <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmitVenue}>
        {!venueId ? <RezylistTextField isError={idError} id='uid' name='uid' label='Id' value={venue?.uid} onChange={(e: any) => setVenue({...venue, uid: e.target.value})} /> : 
          <Box style={{display: venue?.venue_logo ? 'flex' : 'none', height: 150, width: '100%', justifyContent: 'center', alignItems: 'center', padding: '4px', backgroundColor: venue && venue.venue_bgcolor ? 'black' : 'white'}}>
            {venue?.venue_logo ? <img src={venue.venue_logo} style={{maxHeight: '100%', maxWidth: '100%'}} alt='venue logo' /> : <></>}
          </Box>
        }
        <RezylistTextField id='venue_name' label='Name' value={venue?.venue_name || ''} onChange={(e: any) => setVenue({...venue, venue_name: e.target.value})} />
        <FormControl 
            sx={{ marginTop: 0.5, width: '100%' }}>
            <InputLabel id="simple-select-label">PMS Source</InputLabel>
            <Select
              id="pms_source"
              name="pms_source"
              fullWidth
              label='PMS Source'
              value={venue ? venue.pms_source : ''}
              displayEmpty
              onChange={(e: any) => setVenue({...venue, pms_source: e.target.value})}
            >
              <MenuItem key={'priceline'} value={'priceline'}>
                <ListItemText>Priceline</ListItemText>
              </MenuItem>
              <MenuItem key={'ohip'} value={'ohip'}>
                <ListItemText>Oracle / Opera</ListItemText>
              </MenuItem>
              <MenuItem key={'rmscloud'} value={'rmscloud'}>
                <ListItemText>RMS Cloud</ListItemText>
              </MenuItem>
              <MenuItem key={'stayntouch'} value={'stayntouch'}>
                <ListItemText>Stayntouch</ListItemText>
              </MenuItem>
              <MenuItem key={'travelclick'} value={'travelclick'}>
                <ListItemText>TravelClick</ListItemText>
              </MenuItem>
            </Select>
        </FormControl>
        <RezylistTextField id='pms_id' label='PMS Id' required={false} autoFocus={false} value={venue?.pms_id || venue?.hotelid_t || ''} onChange={(e: any) => setVenue({...venue, pms_id: e.target.value})} />
        <div>
          <FormControlLabel
            control={
              <Checkbox 
                id='reservationPMS' name='reservationPMS' color="primary"
                onChange={(e: any) => setReservationPMS(e.target.checked)} 
                checked={reservationPMS} 
              />
            }
            label="Use separate PMS for Reservations"
          />
        </div>
        {reservationPMS ? 
          <>
            <FormControl 
              sx={{ marginTop: 0.5, width: '100%' }}>
              <InputLabel id="simple-select-label">Reservation PMS Source</InputLabel>
              <Select
                id="reservation_pms_source"
                name="reservation_pms_source"
                fullWidth
                label='Reservation PMS Source'
                value={venue?.reservation_pms_source || ''}
                onChange={(e: any) => setVenue({...venue, reservation_pms_source: e.target.value})}
              >
                <MenuItem key={'ohip'} value={'ohip'}>
                  <ListItemText>Oracle / Opera</ListItemText>
                </MenuItem>
                <MenuItem key={'rmscloud'} value={'rmscloud'}>
                  <ListItemText>RMS Cloud</ListItemText>
                </MenuItem>
                <MenuItem key={'stayntouch'} value={'stayntouch'}>
                  <ListItemText>Stayntouch</ListItemText>
                </MenuItem>
                <MenuItem key={'travelclick'} value={'travelclick'}>
                  <ListItemText>TravelClick</ListItemText>
                </MenuItem>
              </Select>
            </FormControl>
            <RezylistTextField id='reservation_pms_id' label='Reservation PMS ID' required={false} autoFocus={false} value={venue?.reservation_pms_id || ''} onChange={(e: any) => setVenue({...venue, reservation_pms_id: e.target.value})} />
          </>
          : <></>
        }
        <RezylistTextField id='venue_phone' label='Venue Phone' type='tel' required={false} autoFocus={false} value={venue?.venue_phone || ''} onChange={(e: any) => setVenue({...venue, venue_phone: e.target.value})} />
        <RezylistTextField id='venue_replyTo' label='Venue Reply-To Email' type='email' required={false} autoFocus={false} value={venue?.venue_replyTo || ''} onChange={(e: any) => setVenue({...venue, venue_replyTo: e.target.value})} />
        <RezylistTextField id='venue_url' label='Venue URL' required={false} autoFocus={false} value={venue?.venue_url || ''} onChange={(e: any) => setVenue({...venue, venue_url: e.target.value})} />
        <RezylistTextField id='venue_icon' label='Venue Page/Tab Icon' required={false} autoFocus={false} value={venue?.venue_icon || ''} onChange={(e: any) => setVenue({...venue, venue_icon: e.target.value})} />
        <RezylistTextField id='venue_logo' label='Venue Logo' required={false} autoFocus={false} value={venue?.venue_logo || ''} onChange={(e: any) => setVenue({...venue, venue_logo: e.target.value})} />
        <div>
          <FormControlLabel
            control={
              <Checkbox 
                id='venue_bgcolor' name='venue_bgcolor' color="primary"
                onChange={(e: any) => setVenue({...venue, venue_bgcolor: e.target.checked})} 
                checked={venue ? venue.venue_bgcolor : false} 
              />
            }
            label="Dark Background"
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox 
                id="skip_availability" name='skip_availability' color="primary" 
                onChange={(e: any) => setVenue({...venue, skip_availability: e.target.checked})} 
                checked={venue ? venue.skip_availability : false} 
              />
            }
            label="Skip Scheduled Availability Check"
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox 
                id='disable_engagement_email' name='disable_engagement_email' color="primary"
                onChange={(e: any) => setVenue({...venue, disable_engagement_email: e.target.checked})} 
                checked={venue ? venue.disable_engagement_email : false} 
              />
            }
            label="Disable Engagement Email"
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox 
                id="allow_roomtypes" name='allow_roomtypes' color="primary" 
                onChange={(e: any) => setVenue({...venue, allow_roomtypes: e.target.checked})} 
                checked={venue ? venue.allow_roomtypes : false} 
              />
            }
            label="Allow Guests to request room types"
          />
        </div>
        {roomTypeList && roomTypeList.length > 0 ? <div>
          <Typography style={{marginTop: 8, marginBottom: 8}} variant='subtitle1' >Room Types:</Typography>
          <RoomTypeDnD 
            list={roomTypeList}
            setList={updateRoomTypeList}  
          />
        </div> : <></>}

        <Box>
          <Button onClick={() => navigate('/dashboard/venues')}>Cancel</Button>
          <Button type="submit" variant="contained">
            {venueId ? 'Update Venue' : 'Add Venue'}
          </Button>
        </Box>
      </form>
    </TableContainer>
    </>
  );
};
