import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { FirebaseContext } from '../firebase/FirebaseContext';

export const LegacyRedirect: React.FC<any> = () => {
  const { db } = useContext(FirebaseContext);
  const { venue } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (venue && db) {        
        const defaultDocRef = doc(
          db,
          'venues',
          venue
        );
        const venueSnap = await getDoc(defaultDocRef);
        const venueData = venueSnap.data();
        
        if (venueData?.pms_id || venueData?.hotelid_t) {
          navigate(`/${venue}/waitlist${location.search}`)
        
        } else if (venueData?.venue_id) {
          navigate(`/${venueData?.venue_id}/waitlist${location.search}`)
        
        } else {
          navigate('/')
        }
      }
    })()
  }, [db, venue, location, navigate]);
  

  return (
    <>
    </>
  );
};
