import React, {  } from 'react';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import { Button, CircularProgress, Container } from '@mui/material';
import Phone from '@mui/icons-material/Phone';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

export const DemoContainer: React.FC<any> = ({ children }) => {
    const navigate = useNavigate();
    return (
        <section style={{ padding: '10px'}}>
            <div style={{maxWidth: 1160, margin: 'auto' }}>
                <img onClick={() => navigate('/')} src='/assets/logo-light.jpeg' style={{cursor: 'pointer', width: '150px', height: 'auto'}} alt='RezyList' />
            </div>
            <Container
            sx={{
                border: 'solid 2px #000',
                margin: 'auto',
                padding: {xs: 1, sm: 4},
                maxWidth: 1160,
                minWidth: {xs: 'auto', sm: 380},
                minHeight: 200,
            }}
            >
                {children}
            </Container>
        </section>
    );
}

export const DemoHotelHeader: React.FC<any> = (({ children }) => {
    return (
        <div style={{display: 'flex', height: 100, flexDirection: 'row', justifyContent: 'space-between', backgroundColor: 'black', padding: 8, fontSize: 14, fontWeight: 'bold'}}>
            <div>
                <img style={{height: 100, width: 'auto'}} src='/assets/VH.webp' alt='Demo' />
            </div>
            <div style={{color: 'white', display: 'flex', alignItems: 'center'}}>
            <Phone style={{width: 18, marginRight: 4}} />
                555-555-1212
            </div>
      </div>
    )
})
export const DemoWaiting: React.FC<any> = ({ children }) => {
    return (
        <div style={{margin: '200px auto', textAlign: 'center', height: 100}}>
            <CircularProgress size={50} />
        </div>
    );
}
export const DemoHeader = styled('div')(({ theme }) => ({
    padding: theme.spacing(0.5),
    fontSize: '2rem',
    fontFamily: 'Poppins',
    fontWeight: '600',
    color: '#333',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.75rem'
    }
}));

export const DemoSubHeader = styled('div')(({ theme }) => ({
    padding: theme.spacing(0.5),
    fontSize: '1.5rem',
    fontFamily: 'Poppins',
    fontWeight: '400',
    color: '#333',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem'
    }
}));

export const DemoText = styled('div')(({ theme }) => ({
    padding: theme.spacing(0.5),
    fontSize: '1rem',
    fontFamily: 'Poppins',
    fontWeight: '400',
    color: '#333',
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem'
    }
}));

export const DemoButton = styled(Button)(({ theme }) => ({
    textTransform: 'uppercase',
    fontFamily: 'Poppins',
    backgroundColor: '#365868',
    borderRadius: 30,
    minWidth: 100,
}));