import {  Link, Typography } from '@mui/material';
import React, {  } from 'react';
import { DemoHotelHeader, DemoSubHeader } from './components';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

export function DemoNoAvailability({onNextClick}: any) {

  return (
    <>
        <DemoSubHeader>You should notice that the standard “no availability” message is now followed by a “waitlist option” message.</DemoSubHeader>
        <DemoSubHeader style={{color: 'red', fontSize: '1.5rem'}}>
          Action: <span style={{fontWeight: 'normal'}}>Continue on by clicking “click here” in the waitlist portion of the message.</span>
        </DemoSubHeader>
        <DemoHotelHeader />
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', padding: 8, border: 'solid 1px #eee'}}>
          <WarningAmberRoundedIcon color='warning' fontSize='large'  />
          <Typography style={{marginLeft: 8}} variant="body1">
            We apologize for the inconvenience. There are no rooms available at the Vandalay Hotel matching your search criteria.
          </Typography>
        </div>
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', padding: 8, border: 'solid 1px #eee'}}>
          <WarningAmberRoundedIcon color='warning' fontSize='large'  />
          <Typography style={{marginLeft: 8}} variant="body1">
          To be notified if a room becomes available on your selected night, please <Typography style={{cursor: 'pointer'}} variant="body1" component={Link} onClick={onNextClick}>click here</Typography> to be added to the Vandalay Hotel waiting list.
        </Typography>
        </div>
    </>
  );
};
