import { Button, Paper, TableContainer, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';

import { FirebaseContext } from '../firebase/FirebaseContext';
import { showSuccessToast } from '../utils';
import { getError, showErrorToast } from '../utils';
import { RezylistTextField } from '../components/form/Inputs';
import { addDoc, collection } from 'firebase/firestore';

export const SupportPage: React.FC<any> = () => {
  const { db, user, currentManager } = useContext(FirebaseContext);
  const [messageText, setMessageText] = useState<string>('');

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      e.stopPropagation();    
      if (!messageText || messageText.trim().length === 0) { return; }  
      const payload: any = {
        to: 'notify@rezylist.com',
        from: `No-reply <notify@rezylist.com>`,
        message: {
          subject: `${process.env.ENVIRONMENT === 'stage' ? '[ STAGING ] ' : ''}Support Request from Dashboard`,
          html: `<b>Support request:</b><br>
                <br />
                <br />Name: ${currentManager.first_name + ' ' + currentManager.last_name}
                <br />Email: ${user?.email || ''}
                <br />Message: ${messageText.toString().replace(/\W/g, ' ')}
                <br />
                `
        }
      }
      await addDoc(collection(db, 'mail'), payload);
      showSuccessToast('Thank you!  We will contact you shortly');
      setMessageText('');
    } catch (e) {
      showErrorToast(getError(e).message)
    }
  }
  return (
    <>
      <TableContainer component={Paper} sx={{display: 'table', maxWidth: '800px', padding: 2, marginBottom: 2}}>
        <Typography variant='h6'>Contact Support</Typography>
        <RezylistTextField 
          id='messageText' 
          label='Please let us know how we can help' 
          value={messageText} 
          minRows='8'
          multiline
          onChange={(e: any) => setMessageText(e.target.value)} 
        />
        <div style={{display: 'flex', justifyContent: 'flex-end', margin: '12px 0'}}>
          <Button style={{marginLeft: 16}} onClick={handleSubmit} variant="contained">
            Submit
          </Button>
        </div>
      </TableContainer>
    </>
  )
};
