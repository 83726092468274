import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import reportWebVitals from './dashboard/reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { FirebaseContextProvider } from './firebase/FirebaseContext';
import { ToastContainer } from 'react-toastify';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LicenseInfo } from '@mui/x-license-pro';

import 'react-toastify/dist/ReactToastify.css';
import { RouteController } from './RouteController';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


LicenseInfo.setLicenseKey('8e02668ef5aced0ae3beb27de3a3485aTz05Mjk2NSxFPTE3NTA2MzE2MjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

// consol.log('APP:', process.env.REACT_APP_PROJECT_ID)
// <React.StrictMode>
root.render(
  <HelmetProvider>
    <Helmet>
      <title>RezyList - Hotel Waitlist</title>
    </Helmet>
    <BrowserRouter>
      <FirebaseContextProvider>
        <RouteController />
      </FirebaseContextProvider>
      <ToastContainer />
    </BrowserRouter>
  </HelmetProvider>
);
// </React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(consol.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
