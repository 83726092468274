import { Button, Paper, TableContainer, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';

import { FirebaseContext } from '../firebase/FirebaseContext';
import { showSuccessToast } from '../utils';
import { getError, showErrorToast } from '../utils';
import { RezylistTextField } from '../components/form/Inputs';
import { doc, updateDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth';

export const MyAccount: React.FC<any> = () => {
    const [formValues, setFormValues] = useState<any>(null);
    const [passwords, setPasswords] = useState<any>({});
    const { db, auth, user, currentManager, setCurrentManager } = useContext(FirebaseContext);

  React.useEffect(() => {
    setFormValues({...currentManager, email: user?.email || ''})
  }, [currentManager, user])

  const handleSubmitManager = async (e: any) => {
    try {
      e.preventDefault();
      e.stopPropagation();      
      const payload: any = {
        first_name: formValues?.first_name,
        last_name: formValues?.last_name,
        update_date: new Date()
      }
      await updateDoc(doc (db, 'managers', currentManager.uid), payload);
      // if (formValues && formValues.email && user && user.email && formValues.email !== user.email) {
      //   // signInWithCredential(auth, auth.)
      //   await updateEmail(user, formValues.email)
      // }
      setCurrentManager({...currentManager, ...payload});
      showSuccessToast('Manager Data Saved');
    } catch (e) {
      showErrorToast(getError(e).message)
    }
  }

  const handlePasswordUpdate = async (e: any) => {
    try {
      e.preventDefault();
      e.stopPropagation();      
      if (!passwords || !passwords.new || !passwords.old) { throw new Error(`Passwords required.`); }
      if (passwords.new !== passwords.confirm) { throw new Error(`Passwords must match.`); }
      if (passwords.new.length < 6) { throw new Error('Minimum Password length is 6 characters.')}
      signInWithEmailAndPassword(auth, user?.email || '', passwords.old)
        .then(async (usercredential) => {
          await updatePassword(usercredential.user, passwords.new)
        })
        .then(() => {
          showSuccessToast('Manager Data Saved');
          setPasswords({})
        })
        .catch((error) => {
          if (error.code === 'auth/wrong-password') {
            showErrorToast('Password incorrect.  Logout to reset password')
          } else {
            showErrorToast(getError(error).message)
          }
        })
    } catch (e) {
      showErrorToast(getError(e).message)
    }
  }
  if (!currentManager || !formValues) {
    return <></>
  }
  return (
    <>
      <TableContainer component={Paper} sx={{display: 'table', maxWidth: '600px', padding: 2, marginBottom: 2}}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          paddingBottom: 8,
        }}>
          <Typography variant="h3">{currentManager.email}</Typography>
        </div>

        <form id='manager_detail' style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmitManager}>
          <Typography variant='h6'>User Profile</Typography>
          {/* <RezylistTextField id='email' label='Email' value={formValues?.email || ''} onChange={(e: any) => setFormValues({...formValues, email: e.target.value})}  /> */}
          <RezylistTextField id='first_name' label='First Name' value={formValues?.first_name || ''} onChange={(e: any) => setFormValues({...formValues, first_name: e.target.value})}  />
          <RezylistTextField id='last_name' label='Last Name' value={formValues?.last_name || ''} onChange={(e: any) => setFormValues({...formValues, last_name: e.target.value})}  />
          <div style={{display: 'flex', justifyContent: 'flex-end', margin: '12px 0'}}>
            <Button style={{marginLeft: 16}} type="submit" variant="contained">
              Save Changes
            </Button>
          </div>
        </form>
      </TableContainer>
      <TableContainer component={Paper} sx={{display: 'table', maxWidth: '600px', padding: 2, marginBottom: 2}}>
        <Typography variant='h6'>Change Password</Typography>
        <RezylistTextField id='old_pwd' label='Old Password' type='password' value={passwords.old || ''} onChange={(e: any) => setPasswords({...passwords, old: e.target.value})}  />
        <RezylistTextField id='new_pwd' label='New Password' type='password' value={passwords.new || ''} onChange={(e: any) => setPasswords({...passwords, new: e.target.value})}  />        
        <RezylistTextField id='confirm' label='Confirm New Password' type='password' value={passwords.confirm || ''} onChange={(e: any) => setPasswords({...passwords, confirm: e.target.value})}  />
        <div style={{display: 'flex', justifyContent: 'flex-end', margin: '12px 0'}}>
          <Button style={{marginLeft: 16}} onClick={handlePasswordUpdate} variant="contained">
            Submit
          </Button>
        </div>
      </TableContainer>
    </>
  )
};
