import React, { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import {
  doc,
  getDoc} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { FirebaseContext } from '../firebase/FirebaseContext';

import { Helmet } from 'react-helmet-async';

import { Copyright } from '../components/Copyright';
import { GuestForm } from './GuestForm';
import { Grid } from '@mui/material';

export const GuestPage: React.FC<any> = () => {
  const [venueData, setVenueData] = useState<any>();
  const [submitted, setSubmitted] = useState(false);
  const { db, analytics } = useContext(FirebaseContext);
  const { venue } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    const callback = () => {
      logEvent(analytics, 'navigate_to_join_waitlist', {
        id: venue
      });
    };
    if (venue && analytics) {
      window.addEventListener('load', callback);
      return () => window.removeEventListener('load', callback);
    }
  }, [venue, analytics]);

  useEffect(() => {
    (async () => {
      if (venue && db) {        
        const defaultDocRef = doc(db,'venues',venue);
        const venueSnap = await getDoc(defaultDocRef);
        if (!venueSnap.exists()) { navigate('/'); return;}
        const venueValues = venueSnap.data();
        if (!venueValues || !venueValues.venue_name) { navigate('/'); return; }
        setVenueData(venueValues);
      }
    })()
  }, [db, navigate, venue]);
  
  const handleWaitlistSuccess = (payload: any) => {
    setSubmitted(true);
  }
  const handleWaitlistError = (error: any) => {

  }

  return (
    <>
    <Helmet>
      <title>{venueData?.venue_name || ''} Waitlist</title>
      {venueData?.venue_icon && <link rel="icon" sizes="16x16" href={venueData.venue_icon} />}
    </Helmet>
      <Card
        style={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          height: 'calc(100vh - 84px)',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            width: '100%',
            background: venueData && venueData.venue_bgcolor ? 'black' : 'white',
            minHeight: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {venueData && venueData.venue_logo && <img alt={venueData.venue_name} src={venueData.venue_logo} style={{maxHeight: 150}} />}
        </div>
        <Grid container style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
          <Grid item xs style={{paddingTop: 40, paddingLeft: 20, paddingRight: 20, maxWidth: 500}}>
          {submitted && (
            <Typography style={{ fontSize: '1.2rem' }}>
              Congratulations! You've successfully been added to <b>{venueData?.venue_name}</b> Reservation Waiting List. You'll be notified when availability is found matching your requested reservation.
            </Typography>
          )}
          {!submitted && (
            <GuestForm
              venueId={venue}
              onSuccess={handleWaitlistSuccess}
              onError={handleWaitlistError}

            />
          )}
          </Grid>
        </Grid>
        <footer
          style={{
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
            padding: '12px 0',
            background: 'black',
          }}
        >
          <div
            style={{
              margin: 'auto',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}>
              <Copyright color={'white'} />
          </div>
        </footer>
      </Card>
    </>
  );
};
