import React, { useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { Image } from "@chakra-ui/react";
import howWedoImage from "../assets/images/howWedo.png";
import MarketingButton from "../components/MarketingButton";
import Shape1 from "../assets/images/shape1.png";
import { useNavigate } from "react-router-dom";

const HowWeDo: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <>
      <Box
        id="HowWeDoIt"
        w="full"
        mt="10%"
        py={{ base: "10", lg: "28" }}
        position="relative"
      >
        <Container maxW="1280" mx="auto">
          <Box
            data-aos="fade-right"
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            flexDirection={{ base: "column-reverse", lg: "row" }}
            gap={{ base: "20", md: "20" }}
          >
            <Box
              px={{ base: "3", lg: "0" }}
              maxW={{ base: "100%", lg: "40%" }}
              height={{ base: "auto", md: "600px" }}
              display="flex"
              flexDirection="column"
              justifyContent="end"
            >
              <Heading
                as="h2"
                mb="2"
                color="#EC8A5E"
                fontSize={{ base: "sm", md: "2xl" }}
                textTransform="uppercase"
              >
                How we do it
              </Heading>
              <Heading
                mb="10"
                as="h1"
                color="#00000"
                fontWeight="600"
                textTransform="uppercase"
                fontSize={{ base: "lg", md: "5xl" }}
              >
                Implementation
              </Heading>
              <OrderedList color="F7F8F8" fontSize="15px" mb="5">
                <ListItem mb="3">
                  RezyList's cloud based technology seamlessly integrates with
                  your current systems
                </ListItem>
                <ListItem mb="3">
                  {" "}
                  We work with SynXis, TravelClick, Pegasys, Cendyn, RMSCloud
                  and all major booking engines
                </ListItem>
                <ListItem mb="3">
                  {" "}
                  RezyList provides for guests to share contact information
                  (email, phone numbers, etc.)
                </ListItem>
                <ListItem mb="3">
                  Our Proprietary Automated Technology Alerts Guests To Book
                  Directly When A Room Becomes Available
                </ListItem>
              </OrderedList>

              <Box mt="5">
                <MarketingButton
                  text="Request a Demo"
                  secProp="linear-gradient(90deg, rgba(232,144,100,1) 0%, rgba(247,199,147,1) 100%)"
                  thirdProp="#000000"
                  forThProp=""
                  drawer=""
                  onclick={() => navigate('/demo')}
                />
              </Box>
            </Box>
            <Box
              width={{ lg: "50%" }}
              data-aos="zoom-out-left
"
            >
              <Image
                width="100%"
                height="100%"
                src={howWedoImage}
                alt="WeDoImage"
              />
            </Box>
          </Box>
        </Container>
        <Box
          maxW="130px"
          display={{ base: "none", md: "block" }}
          position="absolute"
          top="1%"
          left="0"
        >
          <motion.div
            animate={{
              y: [0, -10, 0],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          >
            <Image width="auto" height="100%" src={Shape1} alt="WeDoImage" />
          </motion.div>
        </Box>
      </Box>
    </>
  );
};

export default HowWeDo;
