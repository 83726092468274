import React, { useState } from 'react';

import { ManagerHeader } from '../components/ManagerHeader';
import { WaitlistDatagrid } from '../components/WaitlistDatagrid';

export function Dashboard() {
  const [selectedVenues, setSelectedVenues] = useState<any[]>();

  return (
    <section style={{ padding: '4px' }}>
      <ManagerHeader handleVenueChange={setSelectedVenues} />
      <WaitlistDatagrid venues={selectedVenues} />
    </section>
  );
};
