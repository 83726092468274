// import { collection, DocumentData, getDocs } from 'firebase/firestore';
import React, { useCallback, useContext } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../firebase/FirebaseContext';

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
// import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import PeopleIcon from '@mui/icons-material/People';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import MenuIcon from '@mui/icons-material/Menu';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Logout as LogoutIcon } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { VenueTable } from './VenueTable';
import { VenueDetail } from './VenueDetail';
// import { AuditLogTable } from './AuditLogTable';
import { ManagerTable } from './ManagerTable';
import { httpsCallable } from 'firebase/functions';
import { RequestsTable } from './RequestsTable';
import { ManagerDetail } from './ManagerDetail';
import { ChainTable } from './ChainTable';
import { VenueForm } from './VenueForm';
const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();


export const AdminDashboard: React.FC<any> = () => {
  const { auth, functions } = useContext(FirebaseContext);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [authUserList, setAuthUserList] = React.useState<any[]>([]);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getUserList = useCallback(
    async () => {
      const callable = httpsCallable(functions , 'getListOfAuthAccounts');
      const returnData = (await callable()).data;      
      setAuthUserList(returnData as any[]);
    }, [functions]
  )
  React.useEffect(() => {
    getUserList()
  }, [getUserList]);

  const refreshAuthlist = async () => {
    await getUserList()
  }
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Admin Dashboard
            </Typography>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <List component="nav" sx={{height: '100%'}}>
              <ListItemButton onClick={() => {navigate('venues')}}>
                <ListItemIcon>
                  <MapsHomeWorkIcon />
                </ListItemIcon>
                <ListItemText primary="Venues" />
              </ListItemButton>
              <ListItemButton onClick={() => {navigate('managers')}}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Managers" />
              </ListItemButton>
              <ListItemButton onClick={() => {navigate('chains')}}>
                <ListItemIcon>
                  <WorkspacesIcon />
                </ListItemIcon>
                <ListItemText primary="Chains" />
              </ListItemButton>
              <ListItemButton onClick={() => {navigate('requests')}}>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Waitlist Requests" />
              </ListItemButton>
              {/* <ListItemButton onClick={() => {navigate('logs')}}>
                <ListItemIcon>
                  <MonitorHeartIcon />
                </ListItemIcon>
                <ListItemText primary="Audit Logs" />
              </ListItemButton> */}
            </List>
            <List component="nav">
              <ListItemButton onClick={() => {auth.signOut(); navigate('/'); }}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </List>
          </div>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            flexDirection: 'column'
          }}
        >
          <Toolbar />
          <div style={{ height: 'calc(100vh - 128px)', minHeight: '600px', margin: '16px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Routes>
                  <Route path="venue" element={<VenueForm />} />
                  <Route path="venue/:venueId" element={<VenueForm />} />
                  <Route path="venues" element={<VenueTable />} />
                  <Route path="venues/:venueId" element={<VenueDetail />} />
                  <Route path="managers" element={<ManagerTable refreshAuthlist={refreshAuthlist} authUserList={authUserList} />} />
                  <Route path="managers/:managerId" element={<ManagerDetail authUserList={authUserList} />} />
                  <Route path="chains" element={<ChainTable />} />
                  <Route path="requests" element={<RequestsTable /> } />
                  {/* <Route path="logs" element={<AuditLogTable />} /> */}
                  <Route path="*" element={<Navigate to="requests" />} />
                </Routes>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
