import React, { useCallback, useContext, useState } from 'react';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { collection, collectionGroup, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { DataGridPro, GridCellEditStartParams, GridColDef, MuiEvent } from '@mui/x-data-grid-pro';
import { Box, Paper, TableContainer, CircularProgress, AppBar, Toolbar, FormControlLabel, Checkbox, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import moment from 'moment';
import { DashboardForm } from '../dashboard/DashboardForm';
import { getError, showErrorToast, showInfoToast } from '../utils';
import { Visibility } from '@mui/icons-material';

export const RequestsTable: React.FC<any> = () => {
  const [editState, setEditState] = useState<any>({});
  const [activeData, setActiveData] = useState<any[]>([]);
  const [historicalData, setHistoricalData] = useState<any[]>([]);
  const [showHistorical, setShowHistorical] = useState<boolean>(false);
  const [filterBooked, setFilterBooked] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { currentManager, db } = useContext (FirebaseContext);

  React.useEffect(() => {
    const unsub = onSnapshot(
      query(collectionGroup(db, 'waitlist')),
      (requestsSnap) => {
        const actives:any[] = [];
        const archived:any[] = [];
        requestsSnap.forEach(doc => {
          const parentId = doc.ref.parent.parent?.id
          const reqData = doc.data()
          const dataset = {...reqData, doc_id: doc.id, venue: parentId, venue_uid: parentId}
          if (parentId?.startsWith('test__')) { return; }
          if (reqData.is_archived) {
            archived.push(dataset);
          } else {
            actives.push(dataset);
          }
        })
        setActiveData(actives || []);
        setHistoricalData(archived || [])
      }
    );
    return () => unsub()
  }, [currentManager, db]);

  const handleUpdateWaitlistData = useCallback(
    async (venue_uid: string, payload: any) => {

      try {
        const waitlistRef = collection(db, 'venues', venue_uid, 'waitlist');
        const q = query(waitlistRef, where('id', '==', payload.id));
        const waitlistSnap = await getDocs(q);
        if (waitlistSnap.docs.length > 0) {
          const item = waitlistSnap.docs[0]
          await updateDoc(doc (db, 'venues', venue_uid, 'waitlist', item.id), payload);
        } else {
          throw Error('Record not found!')
        }

        showInfoToast('Successfully updated guest');
      } catch (e) {
        showErrorToast(getError(e).message)
      }
    },
    [db]
  );

  const getDataset = () => {
    const data = showHistorical ? activeData.concat(historicalData) : activeData
    if (filterBooked) {
      return (data || []).filter(x => isReserved(x));
    }
    return data
  }
  const isReserved = (waitlist: any) => {
    return (waitlist.reservations && Object.keys(waitlist.reservations).length > 0 && 
      !Object.values(waitlist.reservations).every((x: any) => x.reservation_status && x.reservation_status.toLowerCase().startsWith('cancel')));
  }

  const columns: GridColDef[] = [
    { 
      field: 'venue', 
      headerName: 'Venue', 
      headerAlign: 'center',
      flex: 1, 
      editable: false,
      renderCell: (params) => {
        const hasReservations = params.row.reservations && Object.keys(params.row.reservations).length > 0;
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton size='small' onClick={() => {
              setEditState(params.row);
              setIsOpen(true);  
            }}>
              {params.row.is_archived ? <Visibility fontSize='small' /> : <EditIcon fontSize='small' /> }
            </IconButton>
            <div style={{
              paddingLeft: 4, 
              paddingTop: 4, 
              fontWeight: hasReservations || params.row.availability_warning ? 'bold' : 'normal', 
              color: hasReservations ? 'green' : params.row.availability_warning ? 'purple' : params.row.is_archived ? 'red' : 'inherit'
            }}>
             {params.value}
            </div>
          </div>
        )
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1, 
      editable: false,
      renderCell: (params) => {
        const hasReservations = params.row.reservations && Object.keys(params.row.reservations).length > 0;

        return (
            <div style={{
              fontWeight: hasReservations || params.row.availability_warning ? 'bold' : 'normal', 
              color: hasReservations ? 'green' : params.row.availability_warning ? 'purple' : params.row.is_archived ? 'red' : 'inherit'
            }}>
              {params.value}
            </div>
        )
      }
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      editable: false,
    },
    {
      field: 'requested_room',
      headerName: 'Room Type',
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{whiteSpace: 'pre-wrap'}}>
            {params.value ? params.value.split(',').join('\n') : ''}
          </div>
        )
      }
    },
    { 
      field: 'checkin', 
      headerName: 'Check In', 
      flex: 1, 
      editable: false,
      valueGetter: (params) => {
        return moment(params.value.toDate()).format('YYYY-MM-DD');
      }
    },
    { 
      field: 'checkout', 
      headerName: 'Check Out', 
      flex: 1, 
      editable: false,
      valueGetter: (params) => {
        return moment(params.value.toDate()).format('YYYY-MM-DD');
      }
    },
    {
      field: 'create_date',
      headerName: 'Create Date',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueGetter: (params) => {
        return moment(params.value.toDate()).format('YYYY-MM-DD');
      }
    },
    { 
      field: 'reason_code', 
      headerName: 'Reason', 
      flex: 0.5, 
      editable: false
    },
  ]
  
  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography
            component="h1"
            variant='h5'
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Waitlist Requests
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <div>
            <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setShowHistorical(e.target.checked)}
                    color='default' 
                    defaultChecked={false} 
                    sx={{
                      color: 'white'
                    }}
                  />
                }
                label="Show historical entries"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setFilterBooked(e.target.checked)}
                    color='default' 
                    defaultChecked={false} 
                    sx={{
                      color: 'white'
                    }}
                  />
                }
                label="Show booked entries only"
              />
            </div>
        </Toolbar>
      </AppBar>
    </Box>
    <TableContainer component={Paper} sx={{display: 'table', width: '100%', padding: 2}}>
    {activeData || historicalData ? <Box 
      sx={{ 
        width: '100%',
        '& .rz_bold': {
          fontWeight: 'bold',
        },
        height: 'calc(100vh - 200px)'
      }}>
        <DataGridPro 
          disableColumnSelector={true}
          getRowId={(row) => row.id}
          getRowHeight={() => {
            return 'auto';
          }}
          disableRowSelectionOnClick
          rows={getDataset()} 
          columns={columns}
          pagination
          pageSizeOptions={[20, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 }},
            sorting: {
              sortModel: [{ field: 'create_date', sort: 'desc' }],
            },
          }}
          onCellEditStart={(params: GridCellEditStartParams, event: MuiEvent) => {
            event.defaultMuiPrevented = true;
          }}
        />
    </Box>
    : <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}> <CircularProgress /> </div> }
    {isOpen && (
      <DashboardForm
        isOpen={isOpen} 
        setIsOpen={(val:boolean) => {
          setEditState(null);
          setIsOpen(val);
        }} 
        venueUid={editState.venue}
        values={editState} 
        onAddToWaitlist={handleUpdateWaitlistData}
      />
    )}
  </TableContainer>
    </>
  )
};
