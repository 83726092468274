import React,{useEffect} from "react"
import { Box, Container, Heading, Image, Text, UnorderedList, ListItem } from "@chakra-ui/react"
import MarketingButton from '../components/MarketingButton'
import AOS from 'aos';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import WeDoImage from "../assets/images/wedo.png"
import Shape1 from "../assets/images/shape1.png"
import arrow from "../assets/images/arrow.png"
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const WhatWedo :React.FC= () => {
    const navigate = useNavigate();   
    useEffect(() => {
        AOS.init({
          duration: 2000,
          once:true
        });
      }, []);
    
    return (
        <>
            <Box w="full"  py="28" position="relative" >
                <Container maxW="1280" mx="auto"  >
                    <Box display="flex" justifyContent="space-around" alignItems="center" flexDirection={{ base: 'column', lg: 'row' }} gap={{ base: '20', md: '12',lg:"0" }}>
                        <Box data-aos="fade-right"   height={{base:'350px',md:'100%'}}  width={{base:"100%",lg:'50%'}} >
                            <Image
                                width="auto"
                                height="auto"
                                src={WeDoImage}
                                alt="WeDoImage"
                            />
                        </Box>
                        <Box data-aos="fade-left"  px={{base:'3',lg:'0'}}>
                            <Heading  as="h2" mb="2" color="#EC8A5E" fontSize={{base:"sm",lg:'2xl'}} textTransform="uppercase">
                                What we Do
                            </Heading>
                            <Heading   as="h1" color="#00000" fontWeight="600" fontSize={{base:"lg",lg:'4xl'}} textTransform="uppercase" >
                                increase your  Revpar
                            </Heading>
                            <Text my="5"  fontSize="md" fontWeight="400" color="#737373">
                                What an automated direct booking waitlist does for you:
                            </Text>

                            <UnorderedList  display="flex" mb="5" fontSize="16px">
                                <ListItem>Reduces OTA fees</ListItem>
                                
                            </UnorderedList>
                            <UnorderedList  >
                            <ListItem >Maintain full occupancy on busiest nights</ListItem>
                            </UnorderedList>

                            <UnorderedList   mt="5"   fontSize="16px" >
                                <ListItem>Avoid overbooking / walking</ListItem>
                               
                            </UnorderedList>
                            <UnorderedList    mt="5">
                            <ListItem>Manage cancellations</ListItem>
                            </UnorderedList >
                            <UnorderedList  mt="5" fontSize="16px">
                                <ListItem>Know demand for rate setting purposes</ListItem>
                            </UnorderedList>

                            <UnorderedList  mt="5" fontSize="16px">
                                <ListItem>Professionally engages guests for future bookings</ListItem>

                            </UnorderedList>
                            <Box mt="10"  >
                                <MarketingButton 
                                    onclick={() => navigate('/demo')}
                                    text='Request a Demo' 
                                    drawer="" 
                                    secProp="linear-gradient(90deg, rgba(232,144,100,1) 0%, rgba(247,199,147,1) 100%)" 
                                    thirdProp='#000000' 
                                    forThProp='' />
                            </Box>


                        </Box>
                    </Box>
                </Container>
                <Box id="section2"  width={{lg:'40%'}} position="absolute" cursor="pointer" top={{ base: "-5%", md: "-10%" }} left={{ base: "22%", md: "15%" }}>
              
                    <Box display={{base:'none',md:'flex'}} gap={6} alignItems="center">
                        <Heading as="h2" color="#B4B4B4" fontSize="2xl" textTransform="uppercase">
                            scroll down
                        </Heading>
                        <Box  position="relative" width="100px" height="100px" display="flex" justifyContent="center" borderRadius="100%" sx={{ border: "1px solid #C5BDBD" }}>
                          <Link duration={500} to="section2" smooth={true}>
                             <motion.div
                                style={{ fontSize: '100px', color: '#B4B4B4', marginTop: "-30px" }}
                                animate={{
                                    y: [0, -10, 0], 
                                }}
                                transition={{
                                    duration: 1.5, 
                                    repeat: Infinity, 
                                    repeatType: 'reverse', 
                                }}
                            >
                               
                                <Image
                                width="auto"
                                height="100%"
                                src={arrow}
                                alt="WeDoImage"
                            />
                            </motion.div></Link>
                           
                        </Box>
                    </Box>
                </Box>


                <Box maxW="180px" display={{base:'none',md:'block'}} position="absolute" top="-20%" left="0">
                    <motion.div 
                      animate={{
                        y: [0, -10, 0], 
                    }}
                    transition={{
                        duration: 1.5, 
                        repeat: Infinity, 
                        repeatType: 'reverse', 
                    }}
                    >
                    <Image
                        width="auto"
                        height="100%"
                        src={Shape1}
                        alt="WeDoImage"
                    />
                    </motion.div>
                   
                </Box>
            </Box>

        </>
    )
}

export default WhatWedo