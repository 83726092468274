
import Grid from '@mui/material/Grid';
import React, {  } from 'react';
import { useNavigate } from 'react-router-dom';
import { RezylistTextField } from '../components/form/Inputs';
import { showErrorToast } from '../utils';
import { DemoButton, DemoHeader, DemoText } from './components';

export function DemoSorry({onNextClick}: any) {

  const navigate = useNavigate();
  
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const form = new FormData(e.target);
      const data = {
        name: form.get('name'),
        email: form.get('email'),
        phone: form.get('phone'),
        message: form.get('message')
      }
      onNextClick(data);
    } catch {
      showErrorToast(`We had a problem: ${e.message}`)
    }
  }
  return (
    <Grid container style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
    <Grid item xs style={{paddingLeft: 20, paddingRight: 20, maxWidth: 500}}>
        <DemoHeader>Rezylist Demo</DemoHeader>
        <DemoText style={{textAlign: 'center'}}>A valid demo key is required.  Please fill out the form below to get your demo key:</DemoText>
        <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmit}>

          <RezylistTextField id={'name'} label='Name' autoFocus={true} />
          <RezylistTextField id={'email'} label='Email' type='email' />
          <RezylistTextField id={'phone'} label='Phone' type='tel' required={false} />
          <RezylistTextField multiline={true} rows={3} id={'message'} label='Message' required={false} />
          <div style={{marginTop: 8, display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
            <DemoButton variant="contained" onClick={() => navigate('/')}>Cancel</DemoButton>
            <DemoButton variant="contained" type="submit">
              Send
            </DemoButton>
          </div>
        </form>
    </Grid>
    </Grid>
  );
};
