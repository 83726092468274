import React, { useCallback, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { signInWithEmailAndPassword } from 'firebase/auth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { FirebaseContext } from '../../firebase/FirebaseContext';
import { NavWrapper } from '../NavWrapper';
import { Copyright } from '../../components/Copyright';
import { dismissToasts, showErrorToast } from '../../utils';
import { CircularProgress } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import moment from 'moment';

const theme = createTheme();

export const SignIn = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = React.useState<boolean>(false);
  const pwdRef = React.useRef<any>(null);
  const { db, auth, user } = useContext(FirebaseContext);

  React.useEffect(() => {
    if (user && user.uid) {
      navigate('/dashboard');
      return;
    }
  }, [navigate, user]);

  const handleSignIn = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        const formItems = new FormData(e.currentTarget);
        
        const email = (formItems.get('email') || '').toString().trim();
        const password = formItems.get('password');
        if (
          !email ||
          !password ||
          typeof email !== 'string' ||
          typeof password !== 'string'
        ) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        signInWithEmailAndPassword(auth,email,password)
          .then(async (data) => { // provides credentials with user object
            const docRef = doc(db, 'managers', data.user?.uid);
            await updateDoc(docRef, { 
              last_login: moment().toDate()
            });
            dismissToasts();
            setSuccess(true);
          }).catch(err => {
            if (err.code === 'auth/invalid-email') {
              showErrorToast('Invalid Email');
            } else {
              showErrorToast('Login failed. Please try again.');
              if (pwdRef && pwdRef.current) { pwdRef.current.value = '' }
            }
          });
      } catch (e: any) {
        showErrorToast(`Login failed. Please try again. ${e.code}`);
      }
    },
    [auth, db]
  );

  return (
    <ThemeProvider theme={theme}>
      <NavWrapper>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              minWidth: 300,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '10em',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {success ? <CircularProgress /> : <Box
              component="form"
              onSubmit={handleSignIn}
              sx={{ mt: 1, width: '100%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={pwdRef}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <RouterLink to="/forgot-password">
                Forgot password?
              </RouterLink>
              {/* <RouterLink to="/sign-up">
                  {"Don't have an account? Sign Up"}
              </RouterLink> */}
            
            </Box> }
          </Box>
          <div
            style={{
              margin: '40px auto',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Copyright />
          </div>
        </Container>
      </NavWrapper>
    </ThemeProvider>
  );
};
