import React, { useCallback, useContext, useState } from 'react';
import { Paper, TableContainer, CircularProgress, Typography } from '@mui/material';
import {  AppBar, Box, Button, Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { addDoc, collection, deleteDoc, doc, getDoc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import { getError, showErrorToast, showSuccessToast } from '../utils';
import { DataGridPro, GridActionsCellItem, GridCellEditStartParams, GridColDef, GridRowId, GridRowParams, MuiEvent } from '@mui/x-data-grid-pro';
import { ChainForm } from './ChainForm';

export const ChainTable: React.FC<any> = () => {
  const [chains, setChains] = useState<any[]>();
  const [editState, setEditState] = useState({});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { currentManager, db } = useContext (FirebaseContext);

  React.useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, 'chains')),
      async (resSnap) => {
        const chainsDataset = resSnap.docs.map(x => ({uid: x.id, ...x.data()}))
        setChains(chainsDataset)
      }
    )
    return () => unsub()
  }, [currentManager, db]);

  const columns: GridColDef[] = [
    {
      field: 'uid',
      type: 'actions',
      getActions: (params: GridRowParams<any>) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon fontSize='small' />}
              label="Edit"
              onClick={handleEdit(params.id)}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon fontSize='small' />}
              label="Delete"
              onClick={handleDelete(params.id)}
            />  
          ]        
      }
    },
    {
      field: 'chain',
      flex: 1,
      headerName: 'Chain',
      editable: false,
    },
    {
      field: 'location_name',
      flex: 1,
      headerName: 'Name',
      editable: false,
    },
    {
      field: 'pms_id',
      flex: 1,
      headerName: 'PMS ID',
      editable: false,
    },
  ]
  const handleUpdateChainData = useCallback(
    async (payload: any) => {

      try {
        const docId = payload.uid || ''
        delete payload.uid
        let docExists = false;
        if (docId) {
          const chainRef = doc(db, 'chains', docId)
          const snap = await getDoc(chainRef);
          if (snap.exists()) {
            await updateDoc(chainRef, payload)
            docExists = true
          } 
        }
        if (!docExists) {
          await addDoc(collection(db, 'chains'), payload);
        }
        showSuccessToast('Chain Data Saved');
      } catch (e) {
        showErrorToast(getError(e).message)
      }
    },
    [db]
  );

  const handleEdit = React.useCallback((docUid: GridRowId) => () => {
    setEditState(chains?.find((i) => i.uid === docUid));
    setIsOpen(true);
  },[chains]);

  const handleDelete = React.useCallback((docUid: GridRowId) => async () => {
    try {
      const id = docUid.toString()
      if (window.confirm('Are you sure?')) {
        await deleteDoc(doc(db, 'chains', id))
        showSuccessToast('Chain Removed');
      }
    } catch (e) {
      showErrorToast(getError(e).message)
    } 
    
  },[db]);

  const openForm = () => {
    setEditState({}); 
    setIsOpen(true);
  }
  
  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <div style={{marginRight: 16}}>
            <Typography
              component="h1"
              variant='h5'
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Chain Locations
            </Typography>
          </div>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            style={{backgroundColor: 'green'}}
            onClick={openForm}
          >
            Add Location
          </Button>
          <div style={{ flexGrow: 1 }} />
          {/* <SearchBox /> */}
        </Toolbar>
      </AppBar>
    </Box>
    <TableContainer component={Paper} sx={{display: 'table', width: '100%', padding: 2}}>
    {chains ? <Box 
      sx={{ 
        width: '100%',
        '& .rz_bold': {
          fontWeight: 'bold',
        },
        height: 'calc(100vh - 200px)'
      }}>
        <DataGridPro 
          disableColumnSelector={true}
          getRowId={(row) => row.uid}
          getRowHeight={() => {
            return 'auto';
          }}
          disableRowSelectionOnClick
          rows={[...chains]} 
          columns={columns}
          pagination
          pageSizeOptions={[20, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 }},
            sorting: {
              sortModel: [{ field: 'chain', sort: 'asc' }],
            },
          }}
          onCellEditStart={(params: GridCellEditStartParams, event: MuiEvent) => {
            event.defaultMuiPrevented = true;
          }}
        />
    </Box>
    : <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}> <CircularProgress /> </div> }

    {isOpen && (
        <ChainForm 
          isOpen={isOpen} 
          setIsOpen={setIsOpen} 
          values={editState} 
          onSubmitForm={handleUpdateChainData}
        />
      )}


  </TableContainer>
  </>
  )
};
