import React from "react";
import { Button } from "@chakra-ui/react";

interface ButtonProps {
  text: string;
  secProp: string;
  thirdProp: string;
  forThProp: string;
  drawer:string;
  type?: any;
  onclick:any;
}

const MarketingButton: React.FC<ButtonProps> = ({
  text,
  secProp,
  thirdProp,
  forThProp,
  drawer,
  onclick,
  type
  
}) => {
  const handleclick=()=>{
  if(onclick){
     onclick()
    
     
  }
  }
  return (
    <>
      <Button
        textTransform="uppercase"
        pt={drawer ? "1":'6'}
        pb={drawer ? "1":'5'}
        px={drawer ? "3":'8'}
        fontSize={drawer ? "13px":'14px'}
        borderRadius={drawer ? "7px":'100px'}
        bgGradient={secProp}
        style={{ border: `${forThProp ? "1px solid black" : ""}` }}
        color={thirdProp}
        width={drawer ? '200px' : 'auto'}
        border="none"
        type={type || 'button'}
        _hover={{bg:'#edd0b0'}}
        transformOrigin="0.5 all"
        onClick={handleclick}
      >
        {text}
      </Button>
    </>
  );
};

export default MarketingButton;
