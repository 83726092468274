import React, { useContext, useState } from 'react';
import { Paper, TableContainer, CircularProgress } from '@mui/material';
import {  Box } from '@mui/material';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { collection, collectionGroup, getDocs, onSnapshot, query } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { actionTypes, getManagerById, isVenueAdmin } from '../utils';
import { DataGridPro, GridCellEditStartParams, GridColDef, MuiEvent } from '@mui/x-data-grid-pro';

export const VenueSettings: React.FC<any> = () => {
  const [venues, setVenues] = useState<any[]>();
  const [activeUids, setActiveUids] = useState<string[]>([]);
  const [archivedUids, setArchivedUids] = useState<string[]>([]);
  const [reservationCount, setReservationCount] = useState<any>({});
  const navigate = useNavigate();
  const { currentManager, db } = useContext (FirebaseContext);

  React.useEffect(() => {
    if (currentManager && !isVenueAdmin(currentManager)) { 
      navigate('/settings')
      return;
    }
    const unsub = onSnapshot(
      query(collection(db, 'venues')),
      async (venueSnap) => {
        const venueDataset = venueSnap.docs.filter(y => currentManager[y.id] && 
                                                currentManager[y.id].includes(actionTypes.VENUE_ADMIN) &&
                                                currentManager.venues.includes(y.id))
                                            .map(x => ({uid: x.id, ...x.data()}));
        const managersSnap = await getDocs(collection(db, 'managers'));
        const mgrDataset = managersSnap.docs.map(x => ({uid: x.id, ...x.data()}));
        const newVenuesList = [];
        for (const venueData of venueDataset) { 
          let newItem:any = {...venueData}
          newItem.venue_admin = [];
          const mgrList = mgrDataset.filter((x:any) => x.venues?.includes(venueData.uid) && !x.is_deactivated)
          for (let i = 0; i < mgrList.length; i++) {
            const mgrData:any = await getManagerById(db, mgrList[i].uid || '');
            if (mgrData && mgrData[venueData.uid] && mgrData[venueData.uid].includes(actionTypes.VENUE_ADMIN)) {
              newItem.venue_admin.push(mgrData.first_name + ' ' + mgrData.last_name);
            }
          }
          newVenuesList.push(newItem);
        }
                                       
        setVenues(newVenuesList)
      }
    )
    return () => unsub()
  }, [currentManager, db, navigate]);

  React.useEffect(() => {

    const unsub = onSnapshot(
      collectionGroup(db, 'waitlist'),
      (res) => {
        const actives:any[] = [];
        const archived:any[] = [];
        const rezylist:any = {};
        res.forEach(doc => {
          const data = doc.data()
          const uid = doc.ref.parent.parent?.id || ''
          if (data && !data.is_archived) {
            actives.push(uid)
          } else {
            archived.push(uid)
          }
          if (data.reservations) {
            rezylist[uid] = (rezylist[uid] || 0) + Object.keys(data.reservations).length; 
          }
        })
        setReservationCount(rezylist || {});
        setActiveUids(actives || []);
        setArchivedUids(archived || []);
      }
    );
          
    return () => unsub();
  }, [db, reservationCount, venues])

  const columns: GridColDef[] = [
    {
      field: 'venue_icon',
      headerName: 'Icon',
      headerAlign: 'right',
      width: 60,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{padding: 4}}>
            {params.row.venue_icon ? <img style={{height: 'auto', width: 30}} src={params.row.venue_icon} alt='Venue Icon' /> : <></>}
          </div>
        )
      }
    },
    {
      field: 'venue_logo',
      headerName: 'Logo',
      headerAlign: 'center',
      flex: 0.5,
      editable: false,
      valueGetter: (params) => {
        return params.row.uid
      },
      renderCell: (params) => {
        return params.row.venue_logo ? 
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4px 2px', backgroundColor: params.row.venue_bgcolor ? 'black' : 'white'}}>
              <div><img style={{width: '100%', height: 32}} src={params.row.venue_logo} alt='Venue Logo' /></div>
            </div>  
          : <></>
      }
    },
    {
      field: 'venue_name',
      headerName: 'Name',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{textAlign: 'center', flexDirection: 'column', display: 'flex'}}>
            <div>{params.row.venue_name}</div>
            <div>
              {params.row.skip_availability ? <div style={{color: 'red', fontWeight: 'bold'}}>Manual Availability Alerts</div> : ''}
            </div>
          </div>
        )
      }
    },
    { 
      field: 'venue_admin', 
      headerName: 'Admin', 
      flex: 0.5,
      headerAlign: 'center',
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{width: '100%', textAlign: 'center'}}>
            {params.row.venue_admin.map((item:any, index: number) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        )
      },
    },
    { field: 'activeCount', 
      headerName: 'Active Requests', 
      headerAlign: 'center',
      flex: 0.25,
      valueGetter: (params) => {
        return activeUids.filter(x => x === params.row.uid).length
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
    { field: 'archivedCount', 
      headerName: 'Archived Requests', 
      headerAlign: 'center',
      flex: 0.25,
      valueGetter: (params) => {
        return archivedUids.filter(x => x === params.row.uid).length
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
    { field: 'totalRequests', 
      headerName: 'Total Requests', 
      headerAlign: 'center',
      flex: 0.25,
      valueGetter: (params) => {
        return activeUids
          .filter(x => x === params.row.uid)
          .concat(archivedUids.filter(x => x === params.row.uid)).length
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
    { field: 'reservation_count', 
    headerName: 'Reservations', 
    headerAlign: 'center',
    flex: 0.25,
    valueGetter: (params) => {
      return Number(reservationCount[params.row.uid] || 0)
    },
    renderCell: (params) => {
      return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
    }
  },
  ]
  
  return (
    <TableContainer component={Paper} sx={{display: 'table', width: '100%', padding: 2}}>
    {venues ? <Box 
      sx={{ 
        width: '100%',
        '& .rz_bold': {
          fontWeight: 'bold',
        },

      }}>
        <DataGridPro 
          autoHeight
          disableColumnSelector={true}
          getRowId={(row) => row.uid}
          disableRowSelectionOnClick
          rows={[...venues]} 
          columns={columns}
          pagination
          pageSizeOptions={[20, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 20 }},
            sorting: {
              sortModel: [{ field: 'waitlistCount', sort: 'desc' }],
            },
          }}
          onCellEditStart={(params: GridCellEditStartParams, event: MuiEvent) => {
            event.defaultMuiPrevented = true;
          }}
        />
    </Box>
    : <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}> <CircularProgress /> </div> }
  </TableContainer>
  )
};
