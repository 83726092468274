import React, { useCallback, useContext, useState } from 'react';
import { Paper, TableContainer, CircularProgress, IconButton, Typography } from '@mui/material';
import {  AppBar, Box, Button, Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { collection, collectionGroup, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { actionTypes, getManagerById } from '../utils';
import { Visibility } from '@mui/icons-material';
import { DataGridPro, GridCellEditStartParams, GridColDef, MuiEvent } from '@mui/x-data-grid-pro';

export const VenueTable: React.FC<any> = ({ action }) => {
  const [venues, setVenues] = useState<any[]>();
  const [settings, setSettings] = useState<any[]>();
  const [venueMgrList, setVenueMgrList] = useState<any>();
  const [activeCount, setActiveCount] = useState<string[]>([]);
  const [archivedCount, setArchivedCount] = useState<string[]>([]);
  const [resyActiveCount, setResyActiveCount] = useState<string[]>([]);
  const [resyArchivedCount, setResyArchivedCount] = useState<string[]>([]);
  const navigate = useNavigate();
  const { db } = useContext (FirebaseContext);

  React.useEffect(() => {
    (async () => {
      const settingsQuery = query(collection(db, 'settings'));
      const settingsSnap = await getDocs(settingsQuery);
      const settingsData = settingsSnap.docs.map(x => ({uid: x.id, ...x.data()}))
      setSettings(settingsData);
    })()
  }, [db])
  React.useEffect(() => {
    if (!settings) { return }
    const unsub = onSnapshot(
      query(collection(db, 'venues'), where('venue_name', '!=', 'null') ),
      async (resSnap) => {
        const venueDataset = resSnap.docs.map(x => ({uid: x.id, ...x.data()}))
        setVenues(venueDataset)
      }
    )
    return () => unsub()
  }, [db, settings]);

  React.useEffect(() => {
    if (!venues) { return }
    const unsub = onSnapshot(
      collectionGroup(db, 'waitlist'),
      (res) => {
        const actives:any[] = [];
        const archived:any[] = [];
        const resyActives:any[] = [];
        const resyArchived:any[] = [];
        res.forEach(doc => {
          const data = doc.data()
          const parentId = doc.ref.parent.parent?.id
          if (data && !data.is_archived) {
            actives.push(parentId);
            if (data.reservations && Object.keys(data.reservations).length > 0) {
              resyActives.push(parentId);
            }
          } else {
            archived.push(parentId);
            if (data.reservations && Object.keys(data.reservations).length > 0) {
              resyArchived.push(parentId);
            }
          }
          
        })
        setActiveCount(actives || []);
        setResyActiveCount(resyActives || []);
        setArchivedCount(archived || []);
        setResyArchivedCount(resyArchived || []);
      }
    );     
    return () => unsub();
  }, [db, venues])

  React.useEffect(() => {
    if (!venues) { return }
    const getManagerList = async () => {
      const mgrQuery = query (collection (db, 'managers'))
      const mgrSnapshot = await getDocs (mgrQuery);
      const venueMgrs:any = {}
      for (let i = 0; i < mgrSnapshot.docs.length; i++) {
        const mgrData:any = await getManagerById(db, mgrSnapshot.docs[i].id || '');
        if (mgrData.is_deactivated) { continue; }
        mgrData.venues.forEach((venueName:string) => {
          if (!venueMgrs[venueName]) { venueMgrs[venueName] = [] }
          const record = {
            name: `${mgrData.first_name} ${mgrData.last_name}`,
            isAdmin: false
          }
          if (mgrData && mgrData[venueName] && mgrData[venueName].includes(actionTypes.VENUE_ADMIN)) {
            record.isAdmin = true
          }
          venueMgrs[venueName].push(record);
        });
      }
      setVenueMgrList(venueMgrs);
      sessionStorage.setItem('adminVenueManagerList', JSON.stringify(venueMgrs));
    };
    const sessionList = sessionStorage.getItem('adminVenueManagerList');
    if (sessionList) {
      setVenueMgrList(JSON.parse(sessionList));
    } else {
      getManagerList();
    }
  }, [db, venues])

  const columns: GridColDef[] = [
    {
      field: 'venue_icon',
      headerName: 'Icon',
      headerAlign: 'right',
      width: 112,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton size='small' onClick={() => navigate(`${params.row.uid}`)}>
              <Visibility fontSize='small' />
            </IconButton>
            <IconButton size='small' data-id={params.row.uid} onClick={handleEdit}>
              <EditIcon fontSize='small' />
            </IconButton>
            <div style={{padding: 4}}>
              {params.row.venue_icon ? <img style={{height: 'auto', width: 30}} src={params.row.venue_icon} alt='Venue Icon' /> : <></>}
            </div>
          </div>

        )
      }
    },
    {
      field: 'venue_logo',
      headerName: 'Logo',
      headerAlign: 'center',
      flex: 0.4,
      editable: false,
      valueGetter: (params) => {
        return params.row.uid
      },
      renderCell: (params) => {
        return params.row.venue_logo ? 
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4px 2px', backgroundColor: params.row.venue_bgcolor ? 'black' : 'white'}}>
              <div><img style={{width: '100%', height: 32}} src={params.row.venue_logo} alt='Venue Logo' /></div>
            </div>  
          : <></>
      }
    },
    {
      field: 'venue_name',
      headerName: 'Name',
      flex: 0.4,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{textAlign: 'center', flexDirection: 'column', display: 'flex'}}>
            <div>{params.row.venue_name}</div>
            <div>
              {params.row.skip_availability ? <div style={{color: 'red', fontWeight: 'bold'}}>Manual Availability Alerts</div> : ''}
            </div>
          </div>
        )
      }
    },
    { 
      field: 'settings', 
      headerName: 'Chain', 
      headerAlign: 'center',
      flex: 0.3,
      valueGetter: (params) => {
        const venueSetting = settings?.find(x => x.uid === params.row.uid);
        if (venueSetting) { 
          return venueSetting.chain || ''
        }
        return '';
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
    { 
      field: 'managers', 
      headerName: 'Managers', 
      flex: 0.5,
      headerAlign: 'center',
      editable: false,
      renderCell: (params) => {
        if (!venueMgrList || Object.keys(venueMgrList).length === 0) { return <div style={{width: '100%', display: 'flex', justifyContent: 'center'}} ><CircularProgress sx={{color: 'gray'}} size={20} /></div> }
        if (!venueMgrList[params.row.uid]) { return <span /> }
        return (
          <div>
            {venueMgrList[params.row.uid]
              .sort((a: any,b: any) => {return b['isAdmin'] - a['isAdmin']})
              .map((item:any, index: number) => 
              (<span key={index}>
                {item.isAdmin ? 
                  <span style={{color: 'green', fontWeight: 'bold'}}>
                    {`${item.name}, `}
                  </span>
                : `${item.name}, `
                }
              </span>)
            )}
          </div>
        )
      },
    },
    { 
      field: 'allow_roomtypes', 
      headerName: 'Room Types', 
      flex: 0.2,
      headerAlign: 'center',
      editable: false,
      type: 'boolean'
    },
    {
      field: 'disable_engagement_email', 
      headerName: 'Engagement Email', 
      headerAlign: 'center',
      flex: 0.2,
      editable: false,
      type: 'boolean',
      valueGetter: (params) => {
        return !params.value
      },
    },
    { field: 'activeCount', 
      headerName: 'Active Requests', 
      headerAlign: 'center',
      flex: 0.2,
      valueGetter: (params) => {
        return activeCount.filter(x => x === params.row.uid).length
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
    { field: 'archivedCount', 
      headerName: 'Archived Requests', 
      headerAlign: 'center',
      flex: 0.2,
      valueGetter: (params) => {
        return archivedCount.filter(x => x === params.row.uid).length
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
    { field: 'totalRequests', 
      headerName: 'Total Waitlist', 
      headerAlign: 'center',
      flex: 0.2,
      valueGetter: (params) => {
        return activeCount
          .filter(x => x === params.row.uid)
          .concat(archivedCount.filter(x => x === params.row.uid)).length
      },
      renderCell: (params) => {
        return <div style={{width: '100%', textAlign: 'center'}}>{params.value}</div>
      }
    },
    { field: 'reservations', 
      headerName: 'Rezy (Active/Archived)', 
      headerAlign: 'center',
      flex: 0.25,
      valueGetter: (params) => {
        return resyActiveCount
          .filter(x => x === params.row.uid)
          .concat(resyArchivedCount.filter(x => x === params.row.uid)).length
      },
      renderCell: (params) => {
        const active = resyActiveCount.filter(x => x === params.row.uid).length;
        const archived = resyArchivedCount.filter(x => x === params.row.uid).length;
        return <div style={{width: '100%', textAlign: 'center'}}>{active + '/' + archived}</div>
      }
    },

  ]


  const handleEdit = useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.id) {
      navigate(`/dashboard/venue/${e.currentTarget.dataset.id}`)
        // setEditState(venues?.find((i) => i.uid === e.currentTarget.dataset.id));
        // setIsOpen(true);
      }
    },[navigate]
  );
  
  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <div style={{marginRight: 16}}>
            <Typography
              component="h1"
              variant='h5'
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Venues
            </Typography>
          </div>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            style={{backgroundColor: 'green'}}
            onClick={() => navigate('/dashboard/venue')}
          >
            Add Venue
          </Button>
          <div style={{ flexGrow: 1 }} />
          {/* <SearchBox /> */}
        </Toolbar>
      </AppBar>
    </Box>
    <TableContainer component={Paper} sx={{display: 'table', width: '100%', padding: 2}}>
    {venues ? <Box 
      sx={{ 
        width: '100%',
        '& .rz_bold': {
          fontWeight: 'bold',
        },
        height: 'calc(100vh - 200px)'
      }}>
        <DataGridPro 
          disableColumnSelector={true}
          getRowId={(row) => row.uid}
          getRowHeight={() => {
            return 'auto';
          }}
          disableRowSelectionOnClick
          rows={[...venues]} 
          columns={columns}
          pagination
          pageSizeOptions={[20, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 }},
            sorting: {
              sortModel: [{ field: 'waitlistCount', sort: 'desc' }],
            },
          }}
          onCellEditStart={(params: GridCellEditStartParams, event: MuiEvent) => {
            event.defaultMuiPrevented = true;
          }}
        />
    </Box>
    : <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}> <CircularProgress /> </div> 
    }
  </TableContainer>
  </>
  )
};
