import React, {  } from 'react';
import { DemoButton, DemoHeader, DemoText } from './components';

export function DemoWelcome({demoSite, onNextClick}: any) {

  return (
    <>
        <DemoHeader>{demoSite?.venue}</DemoHeader>
        <DemoHeader>Welcome to the RezyList Demo!</DemoHeader>
        <DemoText>
            Welcome to the RezyList Demo. This is a quick 3 part demo which should take a few minutes to complete. 
        </DemoText>
        <DemoText>
            Prepare to enjoy this short interactive experience!
        </DemoText>
        <DemoText>
        Some things to note:
            <ol>
            <li>This experience should take less than 5 minutes in total</li>
            <li>You will need to enter a working email and/or mobile number during the demo for a full functionality</li>
            <li>We look forward to offering you a free trial period if you contact us within 48 hours of completing the demo.</li>
            <li>Prepare to be WOWED by the RezyList experience</li>
            </ol>
        </DemoText>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <DemoButton onClick={onNextClick} variant="contained">CLICK HERE TO BEGIN THE DEMO</DemoButton>
        </div>
    </>
  );
};
