import React, { useContext, useEffect } from 'react'
import { Box, Container, Heading, Text, FormControl, Input, Textarea } from "@chakra-ui/react"
import { Image } from '@chakra-ui/react'
import AOS from "aos";
import "aos/dist/aos.css";
import MarketingButton from '../components/MarketingButton'
import Shape1 from "../assets/images/shape1.png"
import emailImage from "../assets/images/email.png"
import emailIcon from "../assets/images/emailIcon.png"
import { addDoc, collection } from 'firebase/firestore';
import { getError, showErrorToast, showSuccessToast } from '../../utils';
import { FirebaseContext } from '../../firebase/FirebaseContext';

const Contact: React.FC = () => {
  const { db } = useContext(FirebaseContext);
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      e.stopPropagation(); 

      const form = new FormData(e.target);
      
      const payload: any = {
        to: 'notify@rezylist.com',
        from: `No-reply <notify@rezylist.com>`,
        message: {
          subject: `${process.env.ENVIRONMENT === 'stage' ? '[ STAGING ] ' : ''}Contact Us request`,
          html: `<b>Contact Us request from the homepage:</b><br>
                <br />
                <br />Name: ${form.get('first_name') + ' ' + form.get('last_name')}
                <br />Email: ${form.get('email')}
                <br />Phone: ${form.get('phone')}
                <br />Message: ${(form.get('message') || '').toString().replace(/\W/g, ' ')}
                <br />
                `
        }
      }
      await addDoc(collection(db, 'mail'), payload);
      showSuccessToast ('Thank you!  We will contact you shortly');
      e.target.reset();

    } catch (e) {
      showErrorToast(getError(e).message)
    }
  }

  return (
    <Box w="full" id="contactus" py={{ base: '0', md: "20" }} position="relative">
      <Container maxW="1280" mx="auto" pt="28">
        <Box display="flex" justifyContent="space-between" flexDirection={{ base: 'column', lg: 'row' }} gap={{ base: '10', md: '10' }}>
          <Box px={{ base: '3', lg: '0' }} width={{ lg: "50%" }} data-aos="flip-right" >
            <Heading as="h2" mb="2" color="#EC8A5E" fontSize="2xl" textTransform="uppercase">
              contact us
            </Heading>
            <Heading as="h1" color="#00000" fontWeight="600" fontSize={{ base: 'lg', lg: '5xl' }} textTransform="uppercase">
              Get In touch
            </Heading>
            <Text my={{base:'3',md:'5'}} fontSize="md" fontWeight="400" color="#B0B0B0">
              For any inquiries or to find out more about our product, please feel free to reach out to our team by sending us an email or filling out our form.
            </Text>
            <Box sx={{ border: '1px solid orange', borderRadius: "12", mt: "10" }} display="flex" alignItems="center" gap="10" py="5" px="10">
              <Box width="75px" height="62px" position="relative">
                <Image width="100%" height="100%" src={emailImage} alt="email" />
                <Image position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" src={emailIcon} alt="email icon" />
              </Box>
              <Box >
                <Heading as="h5" fontSize="2xl" fontWeight="500" textTransform="capitalize">
                  email
                </Heading>
                <Text fontSize="sm" color="#B0B0B0" fontWeight="400">
                  notify@rezylist.com
                </Text>
              </Box>
            </Box>
          </Box>
          <Box width={{ lg: "50%" }}>
            <form onSubmit={handleSubmit} autoComplete='off'>
              <Box display={"flex"} flexDirection={{base:'column',md:'row'}} gap={6}>
                <FormControl mb={{base:'0',md:'10'}}>
                  <Input id='first_name' name='first_name' required={true} height="50px" borderRadius="20" type='text' placeholder='Your Name*' />
                </FormControl>
                <FormControl mb="10">
                  <Input id='last_name' name='last_name' required={true} height="50px" borderRadius="20" type='text' placeholder='Last Name*' />
                </FormControl>
              </Box>
              <Box display={"flex"}  flexDirection={{base:'column',md:'row'}} gap={6}> <FormControl>
                <Input id='email' name='email' type='email' required={true} height="50px" borderRadius="20" placeholder='Email Address*' />
              </FormControl>
                <FormControl>
                  <Input id='phone' name='phone' type='tel' height="50px" borderRadius="20" placeholder='Phone' />
                </FormControl></Box>
              <FormControl mt="10">
                <Textarea required={true} id='message' name='message' sx={{ height: '150px' }} borderRadius="20" placeholder='Your Message*' />
              </FormControl>
              <Box my="10">
                <MarketingButton 
                  onclick={''}
                  type='submit'
                  drawer='' 
                  text='Send A Message' 
                  secProp="linear-gradient(90deg, rgba(232,144,100,1) 0%, rgba(247,199,147,1) 100%)" 
                  thirdProp='#000000' 
                  forThProp='' />
              </Box>
            </form>
          </Box>
        </Box>
      </Container >
      <Box display={{ base: 'none', md: 'block' }}
        maxW="180px" position="absolute" top="-20%" left="0">
        <Image
          width="auto"
          height="100%"
          src={Shape1}
          alt="WeDoImage"
        />
      </Box>


    </Box >
  )
}

export default Contact