import React, {  } from 'react';
import { DemoButton, DemoHeader, DemoText } from './components';

export function DemoOneDone({onNextClick}: any) {

  return (
    <>
        <DemoHeader>CONGRATULATIONS!</DemoHeader>
        <DemoHeader>YOU HAVE COMPLETED PART ONE OF THE DEMO</DemoHeader>
        <DemoText>
            What you have done so far:
            <ul>
                <li>You have gone through the experience of a guest who was trying to book a room at your hotel on a night when you had no vacancy at the time of the guest searching for a room.</li>
                <li>You experienced the opportunity and option to join a waitlist (as opposed to just being told there is no room available).</li>
                <li>You joined the waitlist and received confirmation that you are on the waitlist and will be notified if a room becomes available.</li>
                <li>You are positioned to be notified of an available room to book directly through the hotel – when a room inevitably becomes available when there is a cancellation.</li>
            </ul>
        </DemoText>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <DemoButton onClick={onNextClick} variant="contained">Continue with the demo</DemoButton>
        </div>
    </>
  );
};
