import { Divider, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FirebaseContext } from '../firebase/FirebaseContext';
import { getVenueById } from '../utils';
import { WaitlistDatagrid } from '../components/WaitlistDatagrid';

export const VenueDetail: React.FC<any> = () => {
    const { venueId } = useParams();
    const [venue, setVenue] = useState<any>();
    const { db } = useContext(FirebaseContext);

  React.useEffect(() => {
    if (!venueId) { return }
    const getVenueAsync = async (id:string) => {
        const venue = await getVenueById(db, id);
        setVenue(venue)
    }

    getVenueAsync(venueId)

  }, [db, venueId]);

  if (!venue) {
    return <></>
  }
  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 8,
      }}>
        <Typography variant="h3">{venue.venue_name}</Typography>
      </div>
      <Divider />
      <WaitlistDatagrid venues={[venue]} />
    </>
  )
};
