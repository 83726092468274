import React, { useEffect, useState } from 'react';

// Import the functions you need from the SDKs you need
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getFirestore, Firestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, Auth, onAuthStateChanged, User, connectAuthEmulator } from 'firebase/auth';
import { getAnalytics, Analytics } from 'firebase/analytics';
import { connectFunctionsEmulator, getFunctions, Functions, httpsCallable } from 'firebase/functions';
import { getPerformance } from "firebase/performance";
import { getManagerById } from '../utils';

type FirebaseState = {
  app: FirebaseApp;
  auth: Auth;
  db: Firestore;
  analytics: Analytics;
  functions: Functions;
  user: User | null;
  currentManager: any | null;
  setCurrentManager: any | null;
};

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app)
const perf = getPerformance(app);

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 5050);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

const defaultValue = {
  app,
  auth,
  db,
  analytics,
  functions,
  perf,
  user: null,
  currentManager: null,
  setCurrentManager: null
};

export const FirebaseContext = React.createContext<FirebaseState>(defaultValue);

export const FirebaseContextProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [currentManager, setCurrentManager] = useState<any | null>(null);
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const mgr = await getManagerById(db, currentUser?.uid)
        const logSignInUser = httpsCallable(functions, 'logSignInUser');
        logSignInUser({ 
          ...mgr, 
          uid: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName,
          user_type: mgr && mgr.is_super_admin ? 'SUPERADMIN' : 'MANAGER', 
          venue: mgr?.venues ? mgr.venues.join(', ') : ''
        });
        setCurrentManager(mgr);
      } else {
        setCurrentManager(null);
      }
      setUser(currentUser);
      setAuthChecked(true);
    });

    return unsubscribe;
  }, []);  
  if (!authChecked) { return <></> }
  return (
    <FirebaseContext.Provider value={{ ...defaultValue, user, currentManager, setCurrentManager }}>
      {children}
    </FirebaseContext.Provider>
  );
};
