
import React, { useContext, useEffect, useState } from 'react';
import { DemoSubHeader } from './components';
import {
  doc,
  getDoc} from 'firebase/firestore';
import { Grid } from '@mui/material';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { GuestForm } from '../guest/GuestForm';

export function DemoWaitlistForm({checkin, checkout, onNextClick}: any) {
  const { db } = useContext(FirebaseContext);
  const [venueData, setVenueData] = useState<any>();
  const venueId = 'test__vandalaydemo'

  useEffect(() => {
    (async () => {
      if (db) {        
        const defaultDocRef = doc(db,'venues',venueId);
        const venueSnap = await getDoc(defaultDocRef);
        const venueValues = venueSnap.data();
        setVenueData(venueValues);
      }
    })()
  }, [db]);
    
  return (
    <>
        <DemoSubHeader>You are now on the waitlist intake form for the hotel.</DemoSubHeader>
        <DemoSubHeader style={{color: 'red', fontSize: '1.5rem'}}>
          Action: <span style={{fontWeight: 'normal'}}>Please enter your information to join the waitlist.</span>
        </DemoSubHeader>
        <div
          style={{
            width: '100%',
            background: 'black',
            minHeight: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {venueData && venueData.venue_logo && <img alt={venueData.venue_name} src={venueData.venue_logo} style={{maxHeight: 150}} />}
        </div>
        <Grid container style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
          <Grid item xs style={{paddingTop: 40, paddingLeft: 20, paddingRight: 20, maxWidth: 500}}>
            <GuestForm
              venueId={venueId}
              prevalues={{checkin: checkin, checkout: checkout}}
              onSuccess={() => onNextClick()}
            />
          </Grid>
        </Grid>
    </>
  );
};
