import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Paper, TableContainer, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import {  AppBar, Box, Button, Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { FirebaseContext } from '../firebase/FirebaseContext';
import { collection, doc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { NewUserDialog } from './NewUserDialog';
import { actionTypes, getError, showErrorToast, showSuccessToast } from '../utils';
import { DataGridPro, GridCellParams, GridColDef, GridValueSetterParams } from '@mui/x-data-grid-pro';
import moment from 'moment';

interface MgrTableProps {
  authUserList: any[];
  refreshAuthlist: () => void;
}
export const UserSettings = ({authUserList, refreshAuthlist}: MgrTableProps) => {
  const [actives, setActives] = useState<any[]>();
  const [inactives, setInactives] = useState<any[]>([]);
  const [venueList, setVenueList] = React.useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const { currentManager, db } = useContext (FirebaseContext);
  const navigate = useNavigate();

  // currentManager is assumed to be the Venue Admin
  // Mgr must have at least one venue that is in the currentManager venue list.  
  const isStaffMember = useCallback((mgr: any) => {        
    if (!mgr || !mgr.venues) { return false }
    for (let i = 0; i < mgr.venues.length; i++) {
      let venueUid = mgr.venues[i];
      if (currentManager && currentManager.venues.includes(venueUid) && currentManager[venueUid] && currentManager[venueUid].includes(actionTypes.VENUE_ADMIN)) {
        return true;
      }
    }
    return false;
  }, [currentManager])

  React.useEffect(() => {
    const subscribeManagers = onSnapshot(collection(db, 'managers'),
      async (managerDocs) => {
        if (!authUserList || authUserList.length === 0) { return }
        const activeMgrs:any[] = [];
        const inactiveMgrs:any[] = [];
        
        managerDocs.forEach(doc => {
          const managerData = doc.data()
          if (isStaffMember(managerData) && !managerData.is_super_admin) {
            const authUser = authUserList.find(x => x.uid === doc.id)
            let newItem = {uid: doc.id, ...managerData, ...authUser}
            if (newItem.is_deactivated) {
              inactiveMgrs.push(newItem);
            } else {
              activeMgrs.push(newItem);
            }
          }
        })
        setActives(activeMgrs);
        setInactives(inactiveMgrs);
      }
    )
    return () => subscribeManagers()
  }, [authUserList, db, currentManager, isStaffMember]);

  React.useEffect(() => {
    (async () => {
      const venueQuery = query (collection (db, 'venues'), where ('venue_name', '!=', 'null'))
      const venuesSnap = await getDocs (venueQuery);
      const venueDataset = venuesSnap.docs.map(x => ({uid: x.id, ...x.data()}))

      setVenueList(venueDataset.filter(x => currentManager && currentManager.venues.includes(x.uid) && currentManager[x.uid] && currentManager[x.uid].includes(actionTypes.VENUE_ADMIN)));
    })()
  }, [currentManager, db])

  const columns: GridColDef[] = [
    {
      field: 'uid',
      headerName: '',
      width: 50,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton size='small' data-id={params.row.uid} onClick={() => navigate(`${params.row.uid}`)}>
              <VisibilityIcon fontSize='small' />
            </IconButton>
          </div>

        )
      }
    },
    { 
      field: 'last_name', 
      headerName: 'Name', 
      flex: 1, 
      hideable: false,
      valueGetter: (params) => {
        return `${params.row.first_name} ${params.row.last_name}`
      },
      valueSetter: (params: GridValueSetterParams) => {
        const [first_name, last_name] = params.value!.toString().split(' ');
        return { ...params.row, first_name, last_name };
      },
      renderCell: (params) => {
        return (
          <div style={{
                fontWeight: params.row.is_deactivated ? 'bold' : 'normal', 
                color: params.row.is_deactivated ? 'red' : 'inherit'
          }}>
            {params.value}
          </div>
        )
      } 
    },
    { field: 'email', headerName: 'Email', flex: 1},
    { 
      field: 'venues', 
      headerName: 'Venues', 
      flex: 1,
      cellClassName: (params: GridCellParams<any, string>) => {
        return params.row.is_super_admin ? 'rz_bold' : '' 
      },
      valueGetter: (params) => {
        return params.row.is_super_admin ? 'Super Admin' : params.row.venues ? params.row.venues.join(', ') : ''
      },
      valueSetter: (params: GridValueSetterParams) => {
        return {...params.row, venues: typeof params.value === 'string' ? params.value!.split(', ') : params.value };
      },
    },
    {
      field: 'last_login',
      headerName: 'Last Login',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return <div>{params.value ? moment(params.value.toDate()).format('MMM Do YYYY, h:mm:ss a') : ''}</div>
      }
    },
  ]

  const handleNewManagerAdded = useCallback(
    async (payload: any) => {
      try {
        const docId = payload.uid
        delete payload.uid;
        await setDoc(doc(collection(db, 'managers'), docId), payload);
        refreshAuthlist();
        showSuccessToast('New Manager Added');
        navigate(`${docId}`);
      } catch (e) {
        showErrorToast(getError(e).message)
      }
    }, [db, navigate, refreshAuthlist]
  )
  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            style={{backgroundColor: 'green'}}
            onClick={() => setIsOpen(true)}
          >
            Add User
          </Button>
          <div style={{ flexGrow: 1 }} />
          <div>
            <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setShowInactive(e.target.checked)}
                    color='default' 
                    defaultChecked={false} 
                    sx={{
                      color: 'white'
                    }}
                  />
                }
                label="Show inactive users"
              />
            </div>
        </Toolbar>
      </AppBar>
    </Box>
    <TableContainer component={Paper} sx={{display: 'table', width: '100%', padding: 2}}>
    {actives ? <Box 
      sx={{ 
        width: '100%',
        '& .rz_bold': {
          fontWeight: 'bold',
        },

      }}>
        <DataGridPro 
          autoHeight
          disableColumnSelector={true}
          disableRowSelectionOnClick
          getRowId={(row) => row.uid}
          rows={showInactive ? inactives || [] : actives || []} 
          columns={columns}
          pagination
          pageSizeOptions={[20, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 20 }},
            sorting: {
              sortModel: [{ field: 'email', sort: 'asc' }],
            },
          }}
        />
      </Box>
    : <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}> <CircularProgress /> </div> }
    {isOpen && (
        <NewUserDialog 
          isOpen={isOpen} 
          setIsOpen={setIsOpen} 
          venueList={venueList}
          onSubmitManager={handleNewManagerAdded}
        />
      )}
  </TableContainer>
  </>
  )
};
