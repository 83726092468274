import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Paper, TableContainer, CircularProgress, Typography, FormControlLabel, Checkbox } from '@mui/material';
import {  AppBar, Box, Button, Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { FirebaseContext } from '../firebase/FirebaseContext';
import { collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { NewManagerDialog } from './NewManagerDialog';
import { getError, showErrorToast, showSuccessToast } from '../utils';
import { DataGridPro, GridCellEditStartParams, GridCellParams, GridColDef, GridRowModel, GridValueSetterParams, MuiEvent } from '@mui/x-data-grid-pro';
import moment from 'moment';

interface MgrTableProps {
  authUserList: any[];
  refreshAuthlist: () => void;
}
export const ManagerTable = ({authUserList, refreshAuthlist}: MgrTableProps) => {
  const [actives, setActives] = useState<any[]>();
  const [inactives, setInactives] = useState<any[]>([]);
  const [venueList, setVenueList] = React.useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const { currentManager, db } = useContext (FirebaseContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    const subscribeManagers = onSnapshot(collection(db, 'managers'),
      async (collectionData) => {
        if (!authUserList || authUserList.length === 0) { return }
        const activeMgrs:any[] = [];
        const inactiveMgrs:any[] = [];
        
        collectionData.forEach(doc => {
          const authUser = authUserList.find(x => x.uid === doc.id)
          const data = doc.data()
          let newItem = {uid: doc.id, ...data, ...authUser}
          if (newItem.is_deactivated) {
            inactiveMgrs.push(newItem);
          } else {
            activeMgrs.push(newItem);
          }
        })
        setActives(activeMgrs);
        setInactives(inactiveMgrs);
      }
    )
    return () => subscribeManagers()
  }, [authUserList, db, currentManager]);

  React.useEffect(() => {
    (async () => {
      const venueQuery = query (collection (db, 'venues'), where ('venue_name', '!=', 'null'))
      const venuesSnap = await getDocs (venueQuery);
      const venueDataset = venuesSnap.docs.map(x => ({uid: x.id, ...x.data()}))
      setVenueList(venueDataset);
    })()
  }, [db])
  
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: '',
      width: 50,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton size='small' data-id={params.row.uid} onClick={() => navigate(`${params.row.uid}`)}>
              <VisibilityIcon fontSize='small' />
            </IconButton>
          </div>

        )
      }
    },
    { 
      field: 'last_name', 
      headerName: 'Name', 
      flex: 1, 
      hideable: false,
      valueGetter: (params) => {
        return `${params.row.first_name} ${params.row.last_name}`
      },
      valueSetter: (params: GridValueSetterParams) => {
        const [first_name, last_name] = params.value!.toString().split(' ');
        return { ...params.row, first_name, last_name };
      },
      renderCell: (params) => {
        return (
          <div style={{
                fontWeight: params.row.is_deactivated ? 'bold' : 'normal', 
                color: params.row.is_deactivated ? 'red' : 'inherit'
          }}>
            {params.value}
          </div>
        )
      }
    },
    { field: 'email', headerName: 'Email', flex: 1},
    { 
      field: 'venues', 
      headerName: 'Venues', 
      flex: 2,
      cellClassName: (params: GridCellParams<any, string>) => {
        return params.row.is_super_admin ? 'rz_bold_blue' : '' 
      },
      valueGetter: (params) => {
        return params.row.is_super_admin ? 'Super Admin' : params.row.venues ? params.row.venues.join(', ') : ''
      },
      valueSetter: (params: GridValueSetterParams) => {
        return {...params.row, venues: typeof params.value === 'string' ? params.value!.split(', ') : params.value };
      },
    },
    {
      field: 'last_login',
      headerName: 'Last Login',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return <div>{params.value ? moment(params.value.toDate()).format('MMM Do YYYY, h:mm:ss a') : ''}</div>
      }
    },
  ]

  const handleNewManagerAdded = useCallback(
    async (payload: any) => {
      try {
        const docId = payload.uid
        delete payload.uid;
        await setDoc(doc(collection(db, 'managers'), docId), payload);
        refreshAuthlist();
        showSuccessToast('New Manager Added');
        navigate(`${docId}`);
      } catch (e) {
        showErrorToast(getError(e).message)
      }
    }, [db, navigate, refreshAuthlist]
  )
  const handleUpdateManagerData = useCallback(
    async (newRow: GridRowModel) => {
      try {
        const payload = {
          first_name: newRow.first_name,
          last_name: newRow.last_name,
          venues: newRow.venues || [],
          update_date: new Date()
        }
        const managerRef = doc(db, 'managers', newRow.uid)
        const snap = await getDoc(managerRef);
        if (snap.exists()) {
          await updateDoc(managerRef, payload)
          showSuccessToast('Manager Data Saved');
        } else {
          showErrorToast('We had a problem.  Please refresh the page and try again');
        }
        return newRow;
      } catch (e) {
        showErrorToast(getError(e).message)
      }
    },
    [db]
  );

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    showErrorToast(getError(error).message)
  }, []);

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <div style={{marginRight: 16}}>
            <Typography
              component="h1"
              variant='h5'
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Managers
            </Typography>
          </div>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            style={{backgroundColor: 'green'}}
            onClick={() => setIsOpen(true)}
          >
            Add Manager
          </Button>
          <div style={{ flexGrow: 1 }} />
          <div>
            <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setShowInactive(e.target.checked)}
                    color='default' 
                    defaultChecked={false} 
                    sx={{
                      color: 'white'
                    }}
                  />
                }
                label="Show inactive users"
              />
            </div>
        </Toolbar>
      </AppBar>
    </Box>
    <TableContainer component={Paper} sx={{display: 'table', width: '100%', padding: 2}}>
    {actives ? <Box 
      sx={{ 
        width: '100%',
        '& .rz_bold_blue': {
          fontWeight: 'bold',
          color: 'blue',
        },
        height: 'calc(100vh - 200px)'
      }}>
        <DataGridPro 
          disableColumnSelector={true}
          disableRowSelectionOnClick
          getRowId={(row) => row.uid}
          getRowHeight={() => {
            return 'auto';
          }}
          rows={showInactive ? inactives || [] : actives || []} 
          columns={columns}
          pagination
          pageSizeOptions={[20, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 }},
            sorting: {
              sortModel: [{ field: 'email', sort: 'asc' }],
            },
          }}
          processRowUpdate={handleUpdateManagerData}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          onCellEditStart={(params: GridCellEditStartParams, event: MuiEvent) => {
            if (params.row.is_super_admin && params.field === 'venues') {
              event.defaultMuiPrevented = true;
            }
          }}
        />
      </Box>
    : <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}> <CircularProgress /> </div> }
    {isOpen && (
        <NewManagerDialog 
          isOpen={isOpen} 
          setIsOpen={setIsOpen} 
          venueList={venueList}
          onSubmitManager={handleNewManagerAdded}
        />
      )}
  </TableContainer>
  </>
  )
};
