import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TableContainer, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FirebaseContext } from '../firebase/FirebaseContext';
import { actionList, actionTypes, getManagerById, showSuccessToast } from '../utils';
import { getError, showErrorToast } from '../utils';
import { RezylistTextField } from '../components/form/Inputs';
// import { MultipleSelectCheckmarks } from '../components/form/MultipleSelectCheckmarks';
import { collection, doc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';

export const ManagerDetail: React.FC<any> = ({authUserList}) => {
    const { managerId } = useParams();
    const [mgr, setMgr] = useState<any>(null);
    const [formValues, setFormValues] = useState<any>(null);
    const [venueList, setVenueList] = React.useState<any[]>([]);
    const { db, currentManager } = useContext(FirebaseContext);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [selectedVenues, setSelectedVenues] = React.useState<any[]>([]);

  React.useEffect(() => {
    (async () => {
      const venueQuery = query(collection(db, 'venues'), where('venue_name', '!=', 'null'))
      const venuesSnap = await getDocs(venueQuery);
      const venueDataset = venuesSnap.docs.map(x => ({uid: x.id, ...x.data()}))
      setVenueList(venueDataset.sort());
    })()
  }, [db])
  React.useEffect(() => {
    if (!managerId || !authUserList || authUserList.length === 0) { return }
    const getManagerAsync = async (id:string) => {
        const val = await getManagerById (db, id);
        const authUser = authUserList.find((x: any) => x.uid === id)
        const mgrData = {email: authUser.email, ...val}
        setSelectedVenues((mgrData.venues || []))
        setMgr(mgrData)
    }
    getManagerAsync(managerId)
  }, [authUserList, db, managerId]);

  React.useEffect(() => {
    if (currentManager && currentManager.is_super_admin && !actionList.find(x => x.value === actionTypes.VENUE_ADMIN)) {
      actionList.unshift({
        value: actionTypes.VENUE_ADMIN,
        label: 'Venue Administrator'
      })
    }
  }, [currentManager])

  React.useEffect(() => {
    setFormValues({...mgr})
  }, [mgr])

  const onDeactivateUser = async () => {
    setIsOpen(false);
    const payload = { is_deactivated: !mgr.is_deactivated, update_date: new Date() }
    await updateDoc(doc (db, 'managers', managerId || ''), payload);
    setMgr({...mgr, ...payload});
    showSuccessToast('Manager Data Saved');
  }

  const handleSubmitManager = async (e: any) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      const payload: any = {
        first_name: formValues?.first_name,
        last_name: formValues?.last_name,
        venues: selectedVenues,
        update_date: new Date()
      }
      await updateDoc(doc (db, 'managers', managerId || ''), payload);
      setMgr({...mgr, ...payload});
      showSuccessToast('Manager Data Saved');
    } catch (e) {
      showErrorToast(getError(e).message)
    }
  }

  const handleVenueToggle = (value: number) => () => {
    const currentIndex = selectedVenues.indexOf(value);
    const newChecked = [...selectedVenues];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedVenues(newChecked);
  };

  const handlePermissionChange = async (venueUid: string, action: string) => {
    const actionsRef = doc(db, 'managers', managerId || '', 'venue_actions', venueUid);
    const actions = mgr[venueUid] || []
    let payload = { actions: [] }
    if (actions.includes(action)) { 
      payload = { actions: actions.filter((x: string) => x !== action)}
    } else {
      actions.push(action);
      payload = { actions: actions}
    }
    await setDoc(actionsRef, payload, { merge: true });
    await updateDoc(doc (db, 'managers', managerId || ''), { update_date: new Date() });
    setMgr({...mgr, [venueUid]: payload.actions});
    showSuccessToast('Manager Data Saved');
  }

  if (!mgr || !formValues) {
    return <></>
  }

  return (
    <>
      <TableContainer component={Paper} sx={{display: 'table', maxWidth: '600px', padding: 2, marginBottom: 2}}>
        <div style={{
          paddingBottom: 8,
          color: mgr.is_deactivated ? 'red' : 'inherit',
          textAlign: mgr.is_deactivated ? 'center' : 'left'
        }}>
          <Typography variant="h3">{mgr.first_name + ' ' + mgr.last_name}</Typography>
          {mgr.is_super_admin ? <Typography variant='h6' style={{color: 'blue', fontWeight: 'bold'}}>(SUPERADMIN)</Typography> : <></>}
          {mgr.is_deactivated ? <Typography variant='h6' style={{color: 'red', fontWeight: 'bold'}}>(INACTIVE)</Typography> : <></>}
        </div>

        <form id='manager_detail' style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmitManager}>
          <RezylistTextField id='email' label='Email' disabled={true} defaultValue={mgr?.email || ''} type='email' />
          <RezylistTextField id='first_name' label='First Name' value={formValues?.first_name || ''} onChange={(e: any) => setFormValues({...formValues, first_name: e.target.value})}  />
          <RezylistTextField id='last_name' label='Last Name' value={formValues?.last_name || ''} onChange={(e: any) => setFormValues({...formValues, last_name: e.target.value})}  />
          {/* <MultipleSelectCheckmarks
              key={resetKey}
              optionList={[...venueList]}
              defaultValue={mgr?.venues.join(',')}
              listItemId='uid'
              formId='venues' 
              formLabel='Venues'
              sortKey='uid'
            /> */}
          {mgr?.is_super_admin ? <></> : <div>
            <List dense sx={{ width: '100%', maxWidth: 360,  overflow: 'auto', maxHeight: 300, '& ul': { padding: 0 }, }}>
              {(venueList || []).map((v) => {
                const labelId = `checkbox-list-label-${v.uid}`;

                return (
                  <ListItem
                    key={v.uid}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={handleVenueToggle(v.uid)} dense>
                      <ListItemIcon>
                        <Checkbox
                          style={{padding: 0}}
                          edge="start"
                          checked={selectedVenues.indexOf(v.uid) !== -1}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={v.venue_name} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </div>}
          <div style={{display: 'flex', justifyContent: 'space-between', margin: '12px 0'}}>
            <Button onClick={() => setIsOpen(true)} style={{backgroundColor: mgr.is_deactivated ? 'green' : 'white', color: mgr.is_deactivated ? 'white' : 'red'}} variant="contained">
              {mgr.is_deactivated ? 'Restore' : 'Deactivate'}
            </Button>
            <div>
              <Button style={{marginLeft: 16}} type="submit" variant="contained">
                Save Changes
              </Button>
            </div>
          </div>
        </form>
      </TableContainer>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{mgr.is_deactivated ? 'Restore User' : 'Deactivate User'}</DialogTitle>
        <DialogContent>
          Are you sure you want to {mgr.is_deactivated ? 'restore' : 'deactivate'} {mgr.first_name + ' ' + mgr.last_name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={onDeactivateUser}>
            {mgr.is_deactivated ? 'Restore' : 'Deactivate'}
          </Button>
        </DialogActions>
      </Dialog>
      {mgr && !mgr.is_super_admin && mgr.venues && mgr.venues.length > 0 ? 
      <TableContainer component={Paper} sx={{display: 'table', maxWidth: '600px', padding: 2, marginBottom: 2}}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          paddingBottom: 8,
        }}>
          <Typography variant="h4">Manager Permissions</Typography>
        </div>
        {mgr.venues.map((venueUid: string) => {
          const venueItem = (venueList || []).find((x: any) => x.uid === venueUid)
          return venueItem && venueItem.venue_name ?
            (
              <Accordion
                key={venueUid}
                style={{marginBottom: 8}}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  >
                  <Typography>Permissions for {venueItem.venue_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {actionList && actionList?.length > 0 && actionList.map((item: any) => {
                    const isDefaultAdminAction = mgr[venueUid]?.includes(actionTypes.VENUE_ADMIN) && item.adminDefault
                    return (
                    <div key={item.value}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => handlePermissionChange(venueUid, item.value)}
                            disabled={isDefaultAdminAction}
                            checked={isDefaultAdminAction || mgr[venueUid]?.includes(item.value) || false} 
                          />
                        }
                        label={item.label}
                      />
                    </div>
                  )})}
                </AccordionDetails>
              </Accordion>
            ) : <></>
        })}
      </TableContainer> : <></> 
      }
    </>
  )
};
