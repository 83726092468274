import React, { useCallback, useContext } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { CSVLink } from 'react-csv'

import {  AppBar, Box, Button, Checkbox, FormControlLabel, Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download'
import { FirebaseContext } from '../firebase/FirebaseContext';
import { Remove } from './RemoveDialog';
import { cleanupWaitlistRecords, showInfoToast, showErrorToast, getError, isValidAction, actionTypes } from "../utils";

import moment from 'moment';

export const ActionsBar: React.FC<any> = ({
  venueList,
  venueUid,
  records,
  selected,
  setSelected,
  handleAddGuest,
  handleShowHistorical,
  handleFilterBooked
}) => {
  const { user, db, currentManager } = useContext(FirebaseContext);

  const handleRemoveSelected = useCallback(async () => {
    try {
      if (!user || !currentManager) {
        return;
      }

      let blnMissingPermissons = false;
      selected.forEach(async (item: any) => {
        if (isValidAction(actionTypes.DELETE_WAITLIST_REQUEST, currentManager, item.venue_uid)) {
            const docRef = doc(db, 'venues', item.venue_uid, 'waitlist', item.doc_id);
            await updateDoc(docRef, { 
              is_archived: true,
              archive_date: new Date(),
              update_by: user?.email || user?.uid || 'Manager',
              last_update_by: currentManager ? currentManager.first_name + ' ' + currentManager.last_name : user?.email || 'Manager'
            });
        } else {
          blnMissingPermissons = true;
        }
      })

      setSelected([]);
      if (blnMissingPermissons) {
        showInfoToast('Archived requests.  Some requests were not removed due to missing permissions.')  
      } else {
        showInfoToast('Successfully archived requests.');
      }
    } catch (e) {
      showErrorToast(getError(e).message)
    }
  }, [user, db, setSelected, selected, currentManager]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {venueUid && isValidAction(actionTypes.ADD_WAITLIST_REQUEST, currentManager, venueUid) ? <Button
            startIcon={<AddIcon />}
            variant="contained"
            style={{backgroundColor: 'green'}}
            onClick={() => handleAddGuest()}
          >
            Add Guest
          </Button> : <span></span> }
          {selected.length ? (
            <div style={{ display: 'flex', marginLeft: 20 }}>
              <Remove
                count={selected.length}
                onRemoveSelected={handleRemoveSelected}
              />
            </div>
          ) : null}
          <div style={{ flexGrow: 1 }} />
          <div>
            <FormControlLabel
                control={
                  <Checkbox 
                    onChange={(e) => handleShowHistorical(e.target.checked)}
                    color='default' 
                    defaultChecked={false} 
                    sx={{
                      color: 'white'
                    }}
                  />
                }
                label="Show historical entries"
              />
              <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleFilterBooked(e.target.checked)}
                      color='default' 
                      defaultChecked={false} 
                      sx={{
                        color: 'white'
                      }}
                    />
                  }
                  label="Show booked entries only"
                />
            </div>
          {/* <SearchBox /> */}
          { venueList && venueList.some((x: string) => isValidAction(actionTypes.DOWNLOAD_WAITLIST, currentManager, x)) ? <CSVLink 
            title='Download CSV' 
            style={{margin: '16px 0px 8px 16px', color: 'white'}} 
            filename={`Rezylist_${moment().format('YYYY-MM-DD_hh_mm_a')}`} 
            headers={[
              'doc_id', 
              'venue_uid',
              'name', 
              'email', 
              'phone', 
              'adults', 
              'children', 
              'rooms', 
              'requested_room', 
              'checkin', 
              'checkout', 
              'notify_email', 
              'notify_phone', 
              'special_requests',
              'notes', 
              'last_notification_date', 
              // 'booking_url', 
              // 'returnclick_url', 
              // 'cancel_url',
              'reservation_id',
              'reservation_status',
              'reservation_location',
              'reservation_alternate_room',
              'create_date', 
              'create_by', 
              'update_date',
              'last_update_by', 
              'is_archived', 
            ]}
            data={cleanupWaitlistRecords(records)}
          >
              <DownloadIcon />
          </CSVLink> : <span /> }
        </Toolbar>
      </AppBar>
    </Box>
  );
};
