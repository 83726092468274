import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { FirebaseContext } from '../../firebase/FirebaseContext';

export const Logout = () => {
  const navigate = useNavigate();
  const { auth } = useContext(FirebaseContext);

  React.useEffect(() => {
      auth.signOut();
      navigate('/');
      return;
  }, [auth, navigate]);


  return (
    <></>
  );
};
