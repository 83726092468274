import moment from "moment";

const monthDayFormat = 'YYYY-MM-DD'
const dayTimeFormat = 'YYYY-MM-DD h:mm:ss a'
export const cleanupWaitlistRecords = (records) => {
  return (records || []).map((item) => {
    const cleanRecord = {
      ...item,
      checkin: convertDateTime(item.checkin, monthDayFormat),
      checkout: convertDateTime(item.checkout, monthDayFormat),
      archive_date: convertDateTime(item.archive_date, dayTimeFormat),
      create_date: convertDateTime(item.create_date, dayTimeFormat),
      update_date: convertDateTime(item.update_date, dayTimeFormat),
      last_notification_date: convertDateTime(item.last_notification_date, dayTimeFormat),
    }
    return cleanRecord;
  })    
};
  
const convertDateTime = (item, format) => {
  if (item) return moment(item.toDate()).format(format) // new Date(item._seconds * 1000 + item._nanoseconds/1000000)
  return ''
}