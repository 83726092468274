import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { sendPasswordResetEmail } from 'firebase/auth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import { FirebaseContext } from '../firebase/FirebaseContext';
import { showErrorToast, showInfoToast } from '../utils';


export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { auth } = useContext(FirebaseContext);

  const handleForgotPwdClick = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        const data = new FormData(e.currentTarget);
        const email = (data.get('email') || '').toString().trim();
        if (!email || typeof email !== 'string') {
          return;
        }
        sendPasswordResetEmail(auth, email,)
          .then(() => {
            showInfoToast('Password reset submitted. Please check your email.');
            navigate(`/`);
          }).catch(err => {
            if (err.code === 'auth/invalid-email') {
              showErrorToast('Invalid Email');
            } else {
              showErrorToast('Password reset failed.  Please try again.');
            }
          })
      } catch (e) {
        showErrorToast(`There was an error: ${e}`);
      }
    },
    [auth, navigate]
  );

  return (
      <Box
        sx={{
          minWidth: 300,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleForgotPwdClick}
          sx={{ mt: 1, width: '100%' }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>            
        </Box>
      </Box>
  );
};
