import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

export async function getVenueById(db, uid) {
    const venueDoc = await getDoc(doc(db,'venues',uid));
    return { uid: venueDoc.id, venue_name: '', ...venueDoc.data() }
}

export async function getManagerById(db, uid) {
    const mgrDoc = await getDoc(doc(db, 'managers',uid));
    const actionsSnap = await getDocs(collection(db, 'managers', uid, 'venue_actions'));
    let actionsData = {
        uid: mgrDoc.id,
        is_super_admin: false,
        venues: []
    }
    actionsSnap.forEach(x => {
        const docData = x.data()
        const actions = docData.actions || []
        actionsData[x.id] = [...actions]
    })
    return { ...actionsData, ...mgrDoc.data() }
}