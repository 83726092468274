import React, { useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { confirmPasswordReset, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { FirebaseContext } from '../../firebase/FirebaseContext';
import { NavWrapper } from '../NavWrapper';
import { Copyright } from '../../components/Copyright';
import { dismissToasts, showErrorToast, sleep } from '../../utils';
import { CircularProgress } from '@mui/material';


export const PasswordReset = () => {
  const navigate = useNavigate();
  const { auth } = useContext(FirebaseContext);
  const [verified, setVerified] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(location.search)
      
      const mode = params.get('mode');
      const oobCode = params.get('oobCode');
      if (!mode || !oobCode || mode !== 'resetPassword') { 
        navigate('/');
        return;
      }
      verifyPasswordResetCode(auth, oobCode)
        .then((username) => {
          setEmail(username);
          setVerified(true);
        }).catch (e => {
          showErrorToast('Link is not valid.  Please retry.')
          navigate('/')
        })
    })()
  }, [auth, location.search, navigate])

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        const data = new FormData(e.currentTarget);
        const password = data.get('password');
        const confirmpassword = data.get('confirmpassword');
        if (
          !confirmpassword ||
          !password ||
          typeof confirmpassword !== 'string' ||
          typeof password !== 'string' ||
          password !== confirmpassword
        ) {
          showErrorToast('Passwords must match');
          return;
        }

        const oobCode = new URLSearchParams(location.search).get('oobCode');

        if (oobCode) {
          confirmPasswordReset(auth, oobCode, password)
            .then(() => {
              dismissToasts();
              signInWithEmailAndPassword(auth,email,password)
                .then(async (data) => { // provides credentials with user object
                  setSuccess(true);
                  await sleep(1000);
                  navigate('/dashboard');
                }).catch(err => {
                  showErrorToast('Login failed. Please try again');
                  navigate('/');
                });
            }).catch(err => {
              if (err.code === 'auth/weak-password') {
                showErrorToast('Password must be at least 6 characters');  
              } else {
                showErrorToast('Password Reset failed');
              }
            });
        }
      } catch (e: any) {
        showErrorToast(`There was an error: ${e}`);
      }
    },
    [auth, email, location.search, navigate]
  );

  return (
      <NavWrapper>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              minWidth: 300,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '10em',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography textAlign='center' component="h1" variant="h5">
              Password Reset for<br/><b>{email}</b>
            </Typography>
            {verified && email && !success ? <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: '100%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmpassword"
                label="Confirm Password"
                type="password"
                id="confirmpassword"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset Password
              </Button>            
            </Box> : <CircularProgress /> }
          </Box>
          <div
            style={{
              margin: '40px auto',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Copyright />
          </div>
        </Container>
      </NavWrapper>
  );
};
