import React from 'react';
import { useNavigate } from 'react-router-dom';

export const NavWrapper: React.FC<any> = ({ children }) => {
  const navigate = useNavigate()
  return (
    <section style={{ padding: '10px'}}>
    <div style={{maxWidth: 1160, margin: 'auto' }}>
        <img onClick={() => navigate('/')} src='/assets/logo-light.jpeg' style={{cursor: 'pointer', width: '150px', height: 'auto'}} alt='RezyList' />
    </div>
    <div>
      {children}
    </div>
</section>
  );
};

