import React, { useEffect } from "react";
import { Box, Container, Heading, Text } from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";

import { Image } from "@chakra-ui/react";
import MarketingButton from "../components/MarketingButton";
import aboutBack from "../assets/images/aboutBack.png";
import aboutImage from "../assets/images/aboutRight.png";
import Icon1 from "../assets/images/icon1.png";
import Icon2 from "../assets/images/icon2.png";
import Icon3 from "../assets/images/icon3.png";
import Icon4 from "../assets/images/icon4.png";
import Shape2 from "../assets/images/shape2.png";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const About: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  const aboutData = [
    {
      img: Icon1,
      text: "When there are no vacancies for your property.",
    },
    {
      img: Icon2,
      icon: <ArrowForwardIcon color="#ffdfbc" />,

      text: "Customers gets directed to your property RezyList waitlist.",
    },

    {
      img: Icon3,
      icon: <ArrowForwardIcon  color="#ffdfbc"/>,

      text: "Cancellations lead to open rooms..",
    },
    {
      img: Icon4,
      icon: <ArrowForwardIcon color="#ffdfbc" />,

      text: "The RezyList system immeditely identifies the open room & matches a RezyList guest to book directly at your hotel.",
    },
  ];

  return (
    <>
      <Box
        w="full"
        id="About"
        py={{ base: "10", lg: "10" }}
        height={{ base: "auto", lg: "800px" }}
        bgImage={aboutBack}
        bgRepeat="no-repeat"
        sx={{ backgroundSize: "100% 100%" }}
        position="relative"
      >
        <Container maxW="1280" mx="auto" pt={{ base: "0", lg: "28" }}>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            flexDirection={{ base: "column", lg: "row" }}
            gap={{ base: "7", lg: "10" }}
          >
            <Box data-aos="flip-down">
              <Image
                width="100%"
                height="100%"
                src={aboutImage}
                alt="WeDoImage"
              />
            </Box>
            <Box
              width={{ lg: "50%" }}
              data-aos="fade-left"
              px={{ base: "3", lg: "0" }}
            >
              <Heading
                as="h2"
                mb="2"
                color="#EC8A5E"
                fontSize="lg"
                textTransform="uppercase"
              >
                about us & our services
              </Heading>
              <Heading
                as="h1"
                color="#00000"
                fontWeight="600"
                fontSize={{ base: "sm", md: "4xl" }}
                textTransform="uppercase"
              >
                Your Automated Waitlist Service
              </Heading>
              <Text my="5" fontSize="md" fontWeight="400" color="#726D68">
                Retain, recoup and be reassured that your sold-out property will
                be 100% occupied with more direct hotel bookings. When the
                inevitable last moment cancelations would normally cause revenue
                lost, our waitlist service will give you the peace of mind that
                a direct booking customer is waiting for and ready to directly
                book that now vacant room.
              </Text>
              <Text my="5" fontSize="md" fontWeight="400" color="#726D68">
                RezyList bookings happen directly via your property website.
                With seamless integration, there’s really no reason not to give
                us a try!
              </Text>
              <Box mt="10">
                <MarketingButton
                  text="Request a Demo"
                  secProp="linear-gradient(90deg, rgba(232,144,100,1) 0%, rgba(247,199,147,1) 100%)"
                  thirdProp="#000000"
                  forThProp=""
                  drawer=""
                  onclick={() => navigate('/demo')}
                />
              </Box>
            </Box>
          </Box>
        </Container>

        <Box
          maxW="180px"
          position="absolute"
          display={{ base: "none", md: "block" }}
          bottom="13%"
          right="0"
        >
          <Image width="auto" height="100%" src={Shape2} alt="WeDoImage" />
        </Box>
        {/* bottom  section  */}
        <Box
          display="flex"
          position="relative"
          top="5%"
          alignItems={{ base: "center", lg: "start" }}
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="center"
          mt="20"
          gap="10"
        >
          {aboutData.map((item, index) => (
            <Box key={index} data-aos="fade-right">
              <Box
                mb="5"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  cursor="pointer"
                  position="absolute"
                  fontSize="45px"
                  color="red"
                  left="-15%"
                  px="2"
                  py="2"
                  borderRadius="50%"
                >
                  {" "}
                  {item.icon}
                </Text>

                <Image
                  width="auto"
                  height="auto"
                  src={item.img}
                  alt="WeDoImage"
                />
              </Box>
              <Text width="300px" color="#737373" textAlign="center">
                {item.text}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default About;
