import React, { useState, useEffect, useContext } from "react";

import {
  Box,
  Container,
  Text,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";

import { Image } from "@chakra-ui/react";

import Logo from "../assets/images/logo.png";
import { Link } from 'react-scroll';

import MarketingButton from "./MarketingButton";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { FirebaseContext } from "../../firebase/FirebaseContext";
import { useNavigate } from "react-router-dom";

const MarketingHeader: React.FC = () => {
  const { user } = useContext(FirebaseContext);
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [isOpen, setIsOpen]= useState(false)
  const navigate = useNavigate();
  

  const ChangeNav = () => {
    if (window.scrollY >= 120) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", ChangeNav);
  }, [stickyNavbar]);

  const handletoggleMobile=()=>{
      setIsOpen(true)
  }

  return (
    <>
      <Box
        minW="full"
        bg={stickyNavbar ? "#ffdfbc" : "#FCF1E7"}
        position="fixed"
        top="0"
        zIndex="999"
        className={stickyNavbar ? "stickyNavbar" : ""}
      >
        <Container maxW="1480" mx="auto">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py="3"
          >
            <Box
              display="flex"
              justifyContent="center"
              height="60px"
              width={"25%"}
              alignItems="center"
            >
              <Image height="100%" src={Logo} style={{cursor: 'pointer'}} onClick={() => window.location.reload()} alt="RezyList Home" />
            </Box>
            <Box
              display={{ base: "none", lg: "flex" }}
              alignItems="center"
              justifyContent="end"
              fontWeight="600"
              gap="6"
              width="100%"
            >
              <Link
                to="section2"
                duration={2000}
                smooth={true}
                style={{
                  textDecoration: "none",
                  fontWeight: "lighter",
                  color: "#737373",
                  cursor: "pointer",
                }}
                className="link"
              >
                What We Do
              </Link>

              <Link
                to="About"
                duration={2000}
                smooth={true}
                style={{
                  textDecoration: "none",
                  fontWeight: "lighter",
                  color: "#737373",
                  cursor: "pointer",
                }}
                className="link"
              >
                About Us & Services
              </Link>

              <Link
                to="HowWeDoIt"
                duration={2000}
                smooth={true}
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  fontWeight: "lighter",
                  color: "#737373",
                }}
                className="link"
              >
                How We Do It
              </Link>
              {/* <Link
                to="testimonial"
                duration={2000}
                smooth={true}
                style={{
                  textDecoration: "none",
                  fontWeight: "lighter",
                  cursor: "pointer",
                  color: "#737373",
                }}
                className="link"
              >
                Testimonial
              </Link> */}

              <Link
                to="Technology"
                duration={2000}
                smooth={true}
                style={{
                  textDecoration: "none",
                  fontWeight: "lighter",
                  cursor: "pointer",
                  color: "#737373",
                }}
              
              >
                Technology
              </Link>
              <Link to="contactus"  duration={2000} smooth={true}>
                <MarketingButton
                  text="Contact Us"
                  secProp="linear-gradient(90deg, rgba(232,144,100,1) 0%, rgba(247,199,147,1) 100%)"
                  thirdProp="#000000"
                  forThProp=""
                  drawer=""
                  onclick=""
                />
              </Link>

              <MarketingButton
                text={user && user.uid ? `Dashboard` : `Sign-in`}
                secProp="linear-gradient(90deg, rgba(232,144,100,1) 0%, rgba(247,199,147,1) 100%)"
                thirdProp="#000000"
                forThProp=""
                drawer=""
                onclick={()=> navigate('/sign-in')}
              />
            </Box>

            {/* side bar on toogle  */}

            <Box display={{ base: "block", lg: "none" }}>
              <Button onClick={handletoggleMobile} cursor="pointer">
                <HamburgerIcon />
              </Button>
              <Drawer  onClose={()=>setIsOpen(false)} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerHeader
                    borderBottomWidth="1px"
                    display="flex"
                    bg="#FCF1E7"
                    justifyContent="end"
                    alignItems="center"
                  >
                    <Text color="brown" fontSize={20}>
                      <CloseIcon onClick={()=>setIsOpen(false)} />
                    </Text>
                  </DrawerHeader>
                  <DrawerBody bg="#FCF1E7">
                    <Box
                      display={{ base: "flex", lg: "none" }}
                      alignItems="center"
                      justifyContent="end"
                      flexDirection="column"
                      fontWeight="600"
                      gap="5"
                      my="3"
                    >
                      <Link
                        to="section2"
                        duration={2000}
                        smooth={true}
                        onClick={()=>setIsOpen(false)}
                        style={{
                          textDecoration: "none",
                          fontWeight: "lighter",
                          color: "#737373",
                          cursor: "pointer",
                        }}
                      >
                        What We Do
                      </Link>
                      <Link
                        to="About"
                        duration={2000}
                        onClick={()=>setIsOpen(false)}
                        smooth={true}
                        style={{
                          textDecoration: "none",
                          fontWeight: "lighter",
                          color: "#737373",
                          cursor: "pointer",
                        }}
                      >
                        About Us & Services
                      </Link>

                      <Link
                        to="HowWeDoIt"
                        duration={2000}
                        onClick={()=>setIsOpen(false)}
                        smooth={true}
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          fontWeight: "lighter",
                          color: "#737373",
                        }}
                      >
                        How We Do It
                      </Link>
                     
                      {/* <Link
                        to="testimonial"
                        duration={2000}
                        onClick={()=>setIsOpen(false)}
                        smooth={true}
                        style={{
                          textDecoration: "none",
                          fontWeight: "lighter",
                          cursor: "pointer",
                          color: "#737373",
                        }}
                      >
                        Testimonial
                      </Link> */}

                      <Link
                        to="Technology"
                        duration={2000}
                        onClick={()=>setIsOpen(false)}
                        smooth={true}
                        style={{
                          textDecoration: "none",
                          fontWeight: "lighter",
                          cursor: "pointer",
                          color: "#737373",
                        }}
                      >
                        Technology
                      </Link>

                      <Link to="contactus"  duration={2000} smooth={true}>
                        <MarketingButton
                          text="Contact Us"
                          secProp="linear-gradient(90deg, rgba(232,144,100,1) 0%, rgba(247,199,147,1) 100%)"
                          thirdProp="#737373"
                          forThProp=""
                          drawer="true"
                          onclick={()=>setIsOpen(false)}
                        />
                      </Link>
                      <MarketingButton
                        text={user && user.uid ? `Dashboard` : `Sign-in`}
                        secProp="linear-gradient(90deg, rgba(232,144,100,1) 0%, rgba(247,199,147,1) 100%)"
                        thirdProp="#737373"
                        forThProp=""
                        drawer="true"
                        onclick={()=> { setIsOpen(false); navigate('/sign-in');  }}
                      />
                    </Box>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MarketingHeader;
