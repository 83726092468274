import React, { useContext } from 'react';

// import { Landing } from './landing/Landing';
import { MarketingMain } from './marketing/main';
import { Dashboard } from './dashboard/';
import { CancelMyWaitlistPage } from './guest/CancelMyWaitlistPage';
import { LegacyRedirect } from './guest/legacyRedirect';
import { ReturnClickRedirect } from './guest/ReturnClickRedirect';
import { SignIn } from './landing/auth/SignIn';
import { ForgotPassword } from './landing/auth/ForgotPassword';
import { PasswordReset } from './landing/auth/PasswordReset';
import { AdminDashboard } from './admin';


import { Navigate, Route, Routes } from 'react-router-dom';
import { FirebaseContext } from './firebase/FirebaseContext';
import { DemoIndex } from './demo';
import { GuestPage } from './guest/GuestPage';
import moment from 'moment';
import { SettingsDashboard } from './settings';
import { showErrorToast } from './utils';
import { PrivacyPolicy } from './privacy-policy';
import { Logout } from './landing/auth/logout';

const ValidateAuthUser: React.FC<any> = ({path}) => {
  const { user, currentManager, auth } = useContext(FirebaseContext);
  let isValid = currentManager && currentManager.last_login && !currentManager.is_deactivated && moment(currentManager.last_login.toDate()).isAfter(moment().subtract(7, 'days'));

  if (user?.uid && isValid && currentManager.is_super_admin) { return <AdminDashboard /> }
  if (user?.uid && isValid && currentManager.venues && currentManager.venues.length > 0) {
    if (path === 'dashboard') {
      return <Dashboard /> 
    } else { 
      return <SettingsDashboard /> 
    }
  }
  if (user?.uid && currentManager && currentManager.is_deactivated) {
    showErrorToast('User account is not active')
  }
  auth.signOut()
  return <Navigate to={'/'} replace />
}
export const RouteController = () => {
  return (
    <Routes>
      <Route path="/" element={<MarketingMain />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="sign-in" element={<SignIn />} />
      <Route path="logout" element={<Logout />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="password-reset" element={<PasswordReset />} />
      <Route path="dashboard/*" element={<ValidateAuthUser path={'dashboard'} />} />
      <Route path="settings/*" element={<ValidateAuthUser path={'settings'} />} />
      <Route path="demo/:venueGuid" element={<DemoIndex />} />
      <Route path="demo/*" element={<DemoIndex />} />
      <Route path=":venue/default" element={<LegacyRedirect />} />
      <Route path=":venue/waitlist" element={<GuestPage />} />
      {/* Return Click URL */}
      <Route path="rc/:venue/:waitlist/:payloadUuid" element={<ReturnClickRedirect />} />
      {/* Cancel My Waitlist URL */}
      <Route path="cmw/:venue/:waitlist/:payloadUuid" element={<CancelMyWaitlistPage />} />
      <Route path="*" element={<Navigate to="/" replace /> } />
    </Routes>
  );
};
