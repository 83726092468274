import React from 'react'
import { Box, Container, Image } from "@chakra-ui/react"
import FooterBack from "../assets/images/footer.png"
import FooterLogo from "../assets/images/logo.png"

const MarketingFooter:React.FC= () => {
  return (
    <Box w="full" bgImage={FooterBack} bgRepeat="no-repeat" bgSize="cover">
        <Container maxW="1280"  mx="auto" >
        <Box maxW="500px"  display="flex" justifyContent="center" py="28">
            <Image
                width="auto"
                height="100%"
                src={FooterLogo}
                alt="FooterLogo"
            />
        </Box>
        </Container>
    </Box>
  )
}

export default MarketingFooter