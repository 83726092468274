import React, { useEffect, useState } from "react";
import { Box } from '@chakra-ui/react';
import { ChevronUpIcon } from '@chakra-ui/icons';

import { animateScroll as scroll } from 'react-scroll';

const ScrollToTopButton: React.FC = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      setShowButton(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <Box
      display={showButton ? 'block' : 'none'}
      position="fixed"
      bottom="4"
      right="4"
      borderRadius="full"
      bgGradient="linear-gradient(90deg, rgba(232,144,100,1) 0%, rgba(247,199,147,1) 100%)"
     cursor="pointer"
      p="3"
      zIndex="9999"
    >
      <ChevronUpIcon onClick={handleScrollToTop} color="white" fontSize="25" />
    </Box>
  );
};

export default ScrollToTopButton;
