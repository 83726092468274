
import React, {  } from 'react';
import { DemoButton, DemoText } from './components';

export function DemoWaitlistSuccess({onNextClick}: any) {

  return (
    <>
        <DemoText>As the message below conveys (and the guest would see when entering information as you have), you have now been added to the Hotel Waitlist</DemoText>
        <DemoText>You should have received an email and/or SMS (depending on your selection/entry on the previous page).  Please check your email/SMS now to see the message from the Vandalay Demo Hotel and then <b>return to this page</b>. When you're ready, please <b>click <span style={{color: 'red'}}>NEXT</span> below to continue with the demo</b>.</DemoText>
        <div style={{ textAlign: 'center', paddingTop: 40}}>
          <img style={{width: '100%', height: 'auto'}} src={'/assets/success.png'} alt='success' />

        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <DemoButton onClick={onNextClick} variant="contained">Next</DemoButton>
        </div>
    </>
  );
};
