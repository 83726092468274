import React from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export const Copyright = (props: any) => {
  return (
    <div style={{maxWidth: 200}}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}<Link color="inherit" href="/">Rezylist</Link>{' '}{new Date().getFullYear()}{'.'}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <Link target="_blank" href='/privacy-policy'>Privacy Policy</Link>
      </Typography>
    </div>
  );
};
