import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, {useContext, useEffect} from 'react';
import { FirebaseContext } from '../firebase/FirebaseContext';

export const IdleMonitor: React.FC<any> = ({
  openModal,
  toggleShowIdleModal
}) => {
  //Modal
  const { auth } = useContext(FirebaseContext);
  const idleExtendTime = 1000 * 60;  //1 Minute
  const idleLogoutTime = 1000 * 60; //1 Minute
  let idleExtendEvent: any = undefined;
  let idleLogoutEvent: any = undefined;

  // const sessionTimeout = () => 
  // {  
    // if(!!idleEvent) clearTimeout(idleEvent);
    // if(!!idleLogoutEvent) clearTimeout(idleLogoutEvent);
    // idleEvent = setTimeout(() => toggleShowIdleModal(true), idleTimeout); //show session warning modal.
    // idleLogoutEvent = setTimeout(() => logOut(), idleLogout); //Call logged out on session expire.
  // };

  const showTheDialog = (open: boolean) => {
    toggleShowIdleModal(open)
    if(!!idleLogoutEvent) clearTimeout(idleLogoutEvent);
    if(!!idleExtendEvent) clearTimeout(idleExtendEvent);
    if (open) {
      idleLogoutEvent = setTimeout(() => logOut(), idleLogoutTime); //Log out automatically if no action taken
    }
  }

  const extendSession = () => 
  {
    showTheDialog(false);
    idleExtendEvent = setTimeout(() => logOut(), idleExtendTime); //Call logged out on session expire.
  }

  const logOut = () => 
  {
    toggleShowIdleModal(false);
    auth.signOut();
  }

  useEffect(() => {
      // const events = [
      //     'mousemove',
      //     'click',
      //     'keypress'
      // ];
      // for (let e in events) {
      //     window.addEventListener(events[e], sessionTimeout);
      // }
      showTheDialog(true)
      return () => {
          if(!!idleExtendEvent) clearTimeout(idleExtendEvent);
          if(!!idleLogoutEvent) clearTimeout(idleLogoutEvent);
          // for(let e in events) {
          //     window.removeEventListener(events[e], sessionTimeout);
          // }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Dialog open={openModal}>
      <DialogTitle>
          Session expiration warning
      </DialogTitle>
      <DialogContent>
          You can extend your session for {idleLogoutTime / 60 / 1000} minute or logout now. 
          Do you want to extend the session?
      </DialogContent>
      <DialogActions>
        <button className="btn btn-info"  onClick={()=> logOut()}>Logout</button>
        <button className="btn btn-success" onClick={()=> extendSession()}>Extend session</button>
      </DialogActions>
    </Dialog>
  )
}