
import React, {  } from 'react';
import { DemoButton, DemoText } from './components';

export function DemoDashboardVideo({onNextClick}: any) {

  return (
    <>
        <DemoText>Below you will see and experience the “Manager Dashboard”. The Manager Dashboard is the view of the hotelier regarding the activity of the waitlist. The dashboard has many customizable features and allows for both automated and “manual” outreach and  interaction with the prospective guest. As well, the dashboard provides valuable information to the Hotelier/revenue manager with regard to depth of demand for a given night – which can help with rate setting and ultimately with ADR.</DemoText>
        <DemoText>Click “<span style={{color: 'red'}}>PLAY</span>” (or anywhere on the video) below. When you are finished, click “<span style={{color: 'red'}}>CONTINUE</span>.”</DemoText>
        <div style={{paddingTop: 40}}>
          <video width="100%" height="100%" controls style={{objectFit: 'cover'}}>
            <source src="/assets/dashboarddemo.mp4" type="video/mp4"/>
          </video>
        </div>
        <div style={{padding: 20, display: 'flex', justifyContent: 'center'}}>
          <DemoButton onClick={onNextClick} variant="contained">Continue</DemoButton>
        </div>
    </>
  );
};
