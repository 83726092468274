
import React, {  } from 'react';
import { RezylistTextField } from '../components/form/Inputs';
import { DemoButton, DemoSubHeader } from './components';
import { showErrorToast } from '../utils';
import Grid from '@mui/material/Grid';

export function DemoTrialForm({onNextClick}: any) {

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const form = new FormData(e.target);
      const data = {
        name: form.get('name'),
        email: form.get('email'),
        phone: form.get('phone'),
        message: form.get('message')
      }
      onNextClick(data);
    } catch {
      showErrorToast(`We had a problem: ${e.message}`)
    }
  }

  return (
    <Grid container style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
      <DemoSubHeader>Please enter your info to take advantage of a <b>Free Trial</b> (no setup fee!) or to speak with a representative to see how RezyList can help you.</DemoSubHeader>
      <Grid item xs style={{paddingLeft: 20, paddingRight: 20, maxWidth: 500}}>
      <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmit}>
        <RezylistTextField id={'name'} label='Name' autoFocus={true} />
        <RezylistTextField id={'email'} label='Email' type='email' />
        <RezylistTextField id={'phone'} label='Phone' type='tel' required={false} />
        <RezylistTextField multiline={true} rows={3} id={'message'} label='Message' required={false} />
        <div style={{marginTop: 8, display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
          <DemoButton variant="contained" type="submit">
            Send
          </DemoButton>
        </div>
      </form>
    </Grid>
    </Grid>
  );
};
