import React ,{useEffect} from 'react'
import AOS from 'aos';
import "aos/dist/aos.css";

import {Box ,Container,Heading,Text} from "@chakra-ui/react"
import { Image } from '@chakra-ui/react'
import bannerImg from "../assets/images/banner.png"
import bannerBack from "../assets/images/bannerBack.png"
const Banner:React.FC = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: false,
    });
  }, []);



  return (
    <>
    <Box
      w="full"
     className='banner'
     py="24"
     bgImage={bannerBack} bgRepeat="no-repeat" sx={{backgroundSize:'100% 100%'}}
    >
      <Container maxWidth="1280" mx="auto">
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={{base:'column',lg:"row"}}
          gap={{base:'10',lg:"0"}}
          py={{base:'10',lg:'24'}}
          data-aos="zoom-out-right" 
      
        >
          <Box width={{base:"100%",md:'80%', lg:"40%"}} display="flex" justifyContent="center"  alignItems={{baee:'center',lg:'start'}} flexDirection='column'  >
            <Heading    as="h2" color="#EC8A5E" fontWeight="500" textAlign={{base:'center',lg:'start'}} textTransform="uppercase" fontSize={{base:'2xl',md:'3xl'  ,lg:'4xl'}}>
              increased occupancy
            </Heading>
            <Heading    as="h1" color="#00000"  textTransform="uppercase"fontSize={{base:'3xl',lg:'6xl'}} textAlign={{base:'center',lg:'start'}}>
              More direct <br /> bookings
            </Heading>
            <Text my="5"    fontSize="md" textAlign={{base:'center',lg:'start'}}  color="#737373">
              On your busiest nights, ensure that your hotel is 100% occupied.
              When last minute cancellations and vacancies occur, RezyList
              matches new loyal guests to directly book the open rooms at your
              hotel.
            </Text>
          </Box>
          <Box width={{base:"100%",lg:"60%"}}    display="flex" justifyContent="end">
            <Box      data-aos="fade-left"    width={{base:"100%",lg:"80%"}} height={{base:"300",md:'500'}}>
              <Image
                width="auto"
                height="auto"
                src={bannerImg}
                alt="Banner Image"
              />
            </Box>
          </Box>
        </Box>
      </Container>
     
    </Box>

    </>
  )
}

export default Banner