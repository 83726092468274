import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RemoveIcon from '@mui/icons-material/Remove';

export const Remove: React.FC<any> = ({ count, onRemoveSelected }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        startIcon={<RemoveIcon />}
        color="error"
        variant="contained"
        onClick={() => setIsOpen(true)}
        style={{ marginRight: 10 }}
      >{`Archive (${count})`}</Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{`Archive`}</DialogTitle>
        <DialogContent>
          Are you sure you want to archive {count} items?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={onRemoveSelected}>
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
