import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import { NavWrapper } from '../NavWrapper';
import { Copyright } from '../../components/Copyright';
import { ForgotPasswordForm } from '../../components/ForgotPasswordForm';

export const ForgotPassword = () => {

  return (
      <NavWrapper>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div style={{marginTop: 240}}>
            <ForgotPasswordForm />
          </div>
          <RouterLink to="/sign-in">
            Sign-in
          </RouterLink>
          <div
            style={{
              margin: '40px auto',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Copyright />
          </div>
        </Container>
      </NavWrapper>
  );
};
