import React, {  } from 'react';
import { DemoButton, DemoHeader, DemoText } from './components';

export function DemoTwoDone({onNextClick}: any) {

  return (
    <>
        <DemoHeader>CONGRATULATIONS!</DemoHeader>
        <DemoHeader>YOU HAVE COMPLETED PART TWO OF THE DEMO</DemoHeader>
        <DemoText>
          What you experienced in Part 2:
            <ul>
                <li>After joining the Rezylist waitlist in Part 1 of the demo, Part two simulated the event of a room becoming available.</li>
                <li>You received notification of room availability, included in the notification was a link to book directly at the hotel for your desired stay.</li>
                <li>Your experiences so far have been that of a guest:
                  <ol>
                    <li>Trying to book a room at the hotel on a night that was sold out</li>
                    <li>Adding yourself to the waitlist</li>
                    <li>Being notified that a room has opened-up (due to a cancelation at the hotel)</li>
                    <li>Having the ability to book the room directly on the hotel site.</li>
                  </ol>
                </li>
            </ul>
        </DemoText>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <DemoButton onClick={onNextClick} variant="contained">Continue with the demo</DemoButton>
        </div>
    </>
  );
};
