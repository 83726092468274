import React, { useCallback, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IconButton, TextField, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const RoomTypeDnD = ({list, setList}) => {
    const [editIndex, setEditIndex] = useState();
    const [roomTypeName, setRoomTypeName] = useState();

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    function onDragEnd(result) {
        if (!result.destination) { return; }
        if (result.destination.index === result.source.index) { return; }
        const newList = reorder(list,result.source.index,result.destination.index);
        setList(newList);
    }
    const handleRowChange = (e) => {
        // dataset.id has the index
        const newDataSet = list.map((i, index) => (Number(index) === Number(e.currentTarget.dataset.id) ? { ...i, display_name: roomTypeName } : i));
        setList(newDataSet);
        setEditIndex();
    }

    const handleEdit = useCallback(
        (e) => {
            // dataset.id has the index
          if (e.currentTarget.dataset.id) {
            const dataItem = list[e.currentTarget.dataset.id]
            setRoomTypeName(dataItem.display_name);
            setEditIndex(e.currentTarget.dataset.id);
          }
        },[list]
    );

    return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {list && list.sort((a,b) => {return a['sort_order'] - b['sort_order']}).map((listItem, index) => (
                <Draggable draggableId={(listItem.id || listItem.display_name).toString()} index={index} key={(listItem.id || listItem.display_name).toString()}>
                    {provided => (
                    <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <div style={{
                            width: '100%', 
                            backgroundColor: '#eee', 
                            display: 'flex', 
                            border: 'solid 1px gray', 
                            padding: '4px 8px', 
                            borderRadius: 8, 
                            marginBottom: 4, 
                            justifyContent: 'space-between'
                        }}>
                        {!!editIndex && Number(editIndex) === index ? 
                            <>
                                <TextField
                                    value={roomTypeName}
                                    onChange={e => setRoomTypeName(e.target.value)}
                                    margin="dense"
                                    id={'room_type_display_name'}
                                    name={'room_type_display_name'}
                                    label={'Name'}
                                    fullWidth
                                    color={'primary'}
                                    style={{backgroundColor: 'white'}}
                                />
                                <IconButton size='small' data-id={index} onClick={handleRowChange}>
                                    <CheckBoxIcon style={{color: 'green'}} fontSize='small' />
                                </IconButton>
                                <IconButton size='small' data-id={index} onClick={() => setEditIndex()}>
                                    <CancelIcon style={{color: 'red'}} fontSize='small' />
                                </IconButton>
                            </>
                            :
                            <>
                                <div>
                                    <Typography variant='body1'>
                                        {listItem.display_name}
                                    </Typography>
                                    {listItem.name && listItem.name !== listItem.display_name ? <Typography variant='caption'>
                                        {listItem.name}
                                    </Typography> : <></>}
                                </div>
                                <IconButton size='small' data-id={index} onClick={handleEdit}>
                                    <EditIcon fontSize='small' />
                                </IconButton>
                            </>
                        }
                        </div>
                    </div>
                    )}
                </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
