import React, { useEffect } from "react";
import { Box, Container, Heading, Text } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import MarketingButton from "../components/MarketingButton";
import TechBack from "../assets/images/aboutBack.png";
import Technalogy from "../assets/images/technology.png";
import Shape2 from "../assets/images/shape2.png";
import { useNavigate } from "react-router-dom";

const Technology: React.FC = () => {

  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once:true,
    });
  }, []);

  return (
    <>
      <Box
        w="full"
        id="Technology"
        height={{ base: "auto", lg: "900px" }}
        py={{ base: "0", md: "20" }}
        bgImage={TechBack}
        bgRepeat="no-repeat"
        sx={{ backgroundSize: "100% 100%" }}
        position="relative"
      >
        <Container maxW="1280" mx="auto" pt={{base:'0',lg:'28'}}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ base: "column", lg: "row" }}
            gap={{ base: "20", md: "28" }}
          >
            <Box data-aos="fade-right" width={{ lg: "60%" }}>
              <Image
                width="100%"
                height="100%"
                src={Technalogy}
                alt="WeDoImage"
              />
            </Box>
            <Box width={{ lg: "50%" }}  px={{base:'3',lg:'0'}} data-aos="slide-up">
              <Heading
                as="h2"
                mb="2"
                color="#EC8A5E"
                fontSize="2xl"
                textTransform="uppercase"
              >
                Technology
              </Heading>
              <Heading
                as="h1"
                color="#00000"
                fontWeight="600"
                fontSize="3xl"
                textTransform="uppercase"
              >
                Patent Pending Software Technology
              </Heading>
              <Text my="5" color="#9C958F" fontSize="sm" lineHeight="2">
                Experience the future of hotel waitlist management with our
                cutting-edge patent-pending technology. Our system leverages
                advanced algorithms and real-time data analytics to optimize the
                waitlist process, providing an automated management system of
                direct-booking guests. With its intuitive interface, our
                technology empowers hotels to deliver personalized guest
                experiences while maximizing occupancy and revenue. Stay ahead
                of the competition and streamline your waitlist management with
                RezyList, our game-changing patent-pending technology.
              </Text>
              <Box my="10">
                <MarketingButton
                  text="Request a Demo"
                  secProp="linear-gradient(90deg, rgba(232,144,100,1) 0%, rgba(247,199,147,1) 100%)"
                  thirdProp="#000000"
                  forThProp=""
                  drawer=""
                  onclick={() => navigate('/demo')}
                />
              </Box>
            </Box>
          </Box>
        </Container>

        <Box
          maxW="180px"
          position="absolute"
          bottom="-1%"
          right="0"
           display={{base:'none',md:'block'}}
          data-aos="slide-up"
        >
          <Image width="auto" height="100%" src={Shape2} alt="WeDoImage" />
        </Box>
      </Box>
    </>
  );
};

export default Technology;
