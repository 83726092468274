import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { RezylistTextField } from '../components/form/Inputs';

export const ChainForm: React.FC<any> = ({
  isOpen,
  setIsOpen,
  onSubmitForm,
  values
}) => {

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const form = new FormData(e.target);
    const payload: any = {
      uid: values?.uid || '',
      chain: form.get('chain') || '',
      location_name: form.get('location_name') || '',
      pms_id: form.get('pms_id') || '',
    }
    await onSubmitForm(payload);
    setIsOpen(false);
  }

  const resetForm = () =>{
    setIsOpen(false); 
  }
  return (
    <Dialog open={isOpen} onClose={(event, reason) => {
      if (reason === 'backdropClick') { 
        return
      } else {
        resetForm()
      }
    }}>
      <DialogTitle>{values?.uid ? 'Update Location' : 'Add Location'}</DialogTitle>
      <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmitForm}>
        <DialogContent sx={{pt: 0}}>
          <RezylistTextField id='chain' label='Chain' defaultValue={values?.chain || ''} />
          <RezylistTextField id='location_name' label='Location Name' autoFocus={false} defaultValue={values?.location_name || ''} />
          <RezylistTextField id='pms_id' label='PMS Id' autoFocus={false} defaultValue={values?.pms_id || values?.hotelid_t || ''} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => resetForm()}>Cancel</Button>
          <Button type="submit" variant="contained">
            {values && values?.uid ? 'Update Location' : 'Add Location'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
