import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MultipleSelectCheckmarksProps {
  optionList: string[], 
  allLabel?: string | null,
  defaultValue?: string | null, 
  listItemId?: string,
  sortKey?: string, 
  formId?: string,
  formLabel?: string,
  onUpdate?: (arr: string[]) => void,
}
export const MultipleSelectCheckmarks = ({
  optionList,
  defaultValue,
  sortKey, 
  allLabel,
  listItemId = 'uid',
  formId = 'venues',
  formLabel = 'Venues',
  onUpdate = (arr: string[]) => {},
}: MultipleSelectCheckmarksProps) => {
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
  const [orderedOptionList, setOrderedOptionList] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (sortKey) {
      optionList.sort((a: any, b: any) => {
        if (a[sortKey] > b[sortKey]) return 1;
        if (a[sortKey] < b[sortKey]) return -1;
        return 0
      })
    } else {
      optionList.sort()
    }
    const mappedItems = optionList.map((x: any) => x[listItemId]);
    setOrderedOptionList(Array.from(new Set(mappedItems)));
  }, [listItemId, optionList, sortKey])
  
  React.useEffect(() => {
    setSelectedValues(
      typeof defaultValue === 'string' ? defaultValue.replace(', ', ',').split(',') : [],
    )
  }, [defaultValue])

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value },
    } = event;
    
    // On autofill we get a stringified value.
    const newArray = allLabel && value.includes(allLabel) ? [allLabel] : typeof value === 'string' ? value.split(',') : value
    setSelectedValues(newArray);
    onUpdate(newArray)
  };
  
  return (
    <div>
      <FormControl sx={{ mt: 1, width: '100%' }}>
        <InputLabel id={`${formId}-label`}>{formLabel}</InputLabel>
        <Select
          labelId={`${formId}-label`}
          id={formId}
          name={formId}
          multiple
          value={selectedValues.filter(x => x.length > 0)}
          onChange={handleChange}
          input={<OutlinedInput label={formLabel} />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {allLabel && allLabel.length > 0 ?
            <MenuItem key={'all'} value={allLabel}>
              <Checkbox checked={selectedValues.includes(allLabel)} />
              <ListItemText primary={allLabel} />
            </MenuItem> : <div key={'all'} />
          }
          {orderedOptionList?.length > 0 && orderedOptionList.map((item: string) => (
            <MenuItem key={item} value={item}>
              <Checkbox disabled={(allLabel && selectedValues.includes(allLabel)) || false} checked={selectedValues.indexOf(item) > -1} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}