import { Container, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CheckInCheckOut } from '../components/form/Inputs';
import { DemoButton, DemoHotelHeader, DemoSubHeader } from './components';

export function DemoReservationSearch({onNextClick}: any) {
  const [checkin, setCheckin] = useState<Date | null>(null);
  const [checkout, setCheckout] = useState<Date | null>(null);
  const [datesError, setDatesError] = useState<boolean>(false);

  const handleSearch = () => {
    if (!checkin || !checkout) { setDatesError(true); return }
    onNextClick({checkin, checkout})
  }
  return (
    <>
        <DemoSubHeader>Below is a sample hotel that is currently sold out for all nights</DemoSubHeader>
        <DemoSubHeader style={{color: 'red', fontSize: '1.5rem'}}>
          <b>Action:</b> <span style={{fontWeight: 'normal'}}>Select any check-in and check-out dates and click “search.”</span>
        </DemoSubHeader>
        <DemoHotelHeader />
        <div style={{display: 'flex', justifyContent: 'center', padding: '8px 0'}}>
          <Typography variant='h4'>
            Your Reservation
          </Typography>
        </div>
        <Container sx={{
          display: 'flex', 
          flexDirection: {xs: 'column', sm: 'row'}, 
          alignItems: {xs: 'right', sm: 'center'}, 
          justifyContent: 'space-between', 
          padding: '16px 0', 
          fontSize: 24, 
          fontWeight: 'bold'
        }}>
          <Container sx={{
            padding: {xs: '4px 0', sm: 0},
            width: {xs: '100%', sm: '80%'},
          }}>
            <CheckInCheckOut
                setCheckin={setCheckin}
                isError={datesError}
                checkin={checkin}
                setCheckout={setCheckout}
                checkout={checkout}
              />
          </Container>
          <DemoButton style={{height: 40}} onClick={() => handleSearch()} variant="contained">Search</DemoButton>
        </Container>
        <div style={{width: '100%', display: 'grid', columnGap: 10, gridTemplateColumns: '1fr 1fr 1fr' }}>
          <img style={{height: '100%', width: '100%'}}  src='/assets/1double-1024x716.webp' alt='bed1' />
          <img style={{height: '100%', width: '100%'}}  src='/assets/1queen-1024x716.webp' alt='bed2' />
          <img style={{height: '100%', width: '100%'}}  src='/assets/1king-1024x716.webp' alt='bed3' />
        </div>
    </>
  );
};
