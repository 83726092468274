import React, { useCallback } from 'react';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormLabel from '@mui/material/FormLabel';

export const NumberInput: React.FC<any> = ({ value, name, onChange, min, max }) => {
  const handleIncrement = useCallback(() => {
    if (max) onChange(Math.min(value + 1, max));
    else onChange(value + 1);
  }, [max, onChange, value]);
  const handleDecrement = useCallback(
    () => onChange(Math.max(value - 1, min)),
    [min, onChange, value]
  );

  return (
    <div
      style={{
        display: 'flex',
        height: '50px',
        alignItems: 'center',
        width: '250px',
        justifyContent: 'space-between',
        paddingLeft: '1em',
      }}
    >
      <FormLabel>{name}</FormLabel>
      <div>
        <Button size="small" onClick={handleDecrement}>
          <RemoveIcon />
        </Button>
        {value}
        <Button size="small" onClick={handleIncrement}>
          <AddIcon />
        </Button>
      </div>
    </div>
  );
};
