import React, {useContext, useEffect, useState} from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { sleep } from '../utils';

export const VenueAvailabilityDialog: React.FC<any> = ({
  openModal,
  handleCancel,
  handleContinue,
  venueSettingsData,
  guestForm
}) => {
  const { functions } = useContext(FirebaseContext);
  const [venueAvailable, setVenueAvailable] = useState<boolean>(false);

  useEffect(() => {
    const runCheck = async () => {
      const checkVenueIsAvailable = httpsCallable(functions, 'checkVenueIsAvailable');
      const hasAvailability = await checkVenueIsAvailable(guestForm);
      const val = Boolean(hasAvailability.data || false)
      setVenueAvailable(val);
      await sleep(250);
      if (!val) {
        handleContinue(false)
      }
    }
    
    if (venueSettingsData && venueSettingsData.guest_form_availability_check && guestForm && guestForm.venueUid) {
      setVenueAvailable(false);
      runCheck()
    } else {
      handleContinue(false);
    }
  }, [functions, handleContinue, guestForm, venueSettingsData]);

  if (!venueSettingsData || !venueSettingsData.guest_form_availability_check) {
    return <></>
  }
  return (
    <Dialog open={openModal}>
      <DialogTitle style={{minWidth: 300}}>
          {venueAvailable ? 'Rooms currently available' : 'Submitting request'}
      </DialogTitle>
      <DialogContent>
          {venueAvailable ? 'There are rooms available for your waitlist selected criteria. Please go back to the booking page and try booking directly from there. If you are still encountering issues, please call the reservation desk at the hotel.' : `Please wait...`}
          <Box sx={{ width: '100%'}}>
            {venueAvailable ? <></> : <LinearProgress /> }
          </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={!venueAvailable} onClick={() => handleCancel()} variant="contained">Go Back</Button>
        {/* <Button disabled={!venueAvailable} style={{minWidth: 102}} onClick={() => handleContinue(true)}>JOIN WAITLIST</Button> */}
      </DialogActions>
    </Dialog>
  )
}