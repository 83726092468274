
import React, {  } from 'react';
import { DemoButton, DemoSubHeader, DemoText } from './components';

export function DemoCheckYourAlerts({onNextClick}: any) {

  return (
    <>
        <DemoSubHeader>Success!</DemoSubHeader>
        <DemoText>Please check your email/SMS for notification of room availability and then <b>return to this page after seeing the email/SMS</b>.</DemoText>
        <DemoText>Continue when you are ready…</DemoText>
        <div style={{padding: 20, display: 'flex', justifyContent: 'center'}}>
          <DemoButton onClick={onNextClick} variant="contained">Continue</DemoButton>
        </div>
    </>
  );
};
